import { DeleteFilled, EditFilled } from '@ant-design/icons';
import { Space, Table } from 'antd';
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { ErrorMessage, SuccessMessage } from '../../../components/Messages/messages';
import { Layout } from '../../../layout/Layout';

export const Blogs = () => {
    const [data, setData] = useState([]);
    const getData = async () => {
        await axios.get(`https://bazrbuzz.herokuapp.com/api/blogs/`).then(res => {
            if (res.statusText === "OK") {
                setData(res.data);
            } else {
                ErrorMessage(res.data.errorMessage);
            }
        })
    }

    useEffect(() => {
        getData()
        return () => {
        }
    }, []);


    const deleteHandler = async (id) => {
        await axios.delete(`https://bazrbuzz.herokuapp.com/api/blogs/delete/${id}`, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('token')
            }
        }).then(res => {
            if (res.statusText === "OK") {
                SuccessMessage(res.data.successMessage);
                getData();
            } else {
                Error(res.data.errorMessage);
            }
        })
    }

    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Sub Title',
            dataIndex: 'subTitle',
            key: 'subTitle',
        },
        {
            title: 'Picture',
            key: 'picture',
            render: (_, record) => (
                <Space size="middle">
                    {
                        record?.picture?.type === "video" ?
                            <video src={record.picture.url} />
                            :
                            <img src={record.picture.url} alt={record.title} />
                    }
                </Space>
            ),
        },
        {
            title: 'description',
            key: 'description',
            render: (_, record) => (
                <Space size="middle" className='description-text'>
                    <div dangerouslySetInnerHTML={{ __html: record.description }}></div>
                </Space>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Link to={"/admin/update-blog/" + record._id}>
                        <EditFilled />
                    </Link>
                    <DeleteFilled onClick={() => deleteHandler(record._id)} />
                </Space>
            ),
        },
    ];

    return (
        <Layout sidebar>
            <div className='flex justify-end mt-4 p-4'>
                <Link to="/admin/create-blog" className='bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded'>Upload Blog</Link>
            </div>
            <div className='blogs'>
                <Table columns={columns} dataSource={data} />
            </div>
        </Layout>
    )
}
