import { MenuFoldOutlined } from '@ant-design/icons';
import { Col, Drawer, Row } from 'antd';
import React, { useEffect, useState } from 'react'
import { AdminSideBar } from '../components/Admin/Sidebar/AdminSideBar';
import "./Admin.css"

export const Layout = (props) => {
  const [open, setOpen] = useState(false);
  const [isMobile, setIsMobile] = useState("");

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const handleResize = () => {
    if (window.innerWidth < "767") {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    if (window.innerWidth < "767") {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }

    return () => {

    }
  }, []);


  return (
    <div className='admin-layout'>
      {
        props.sidebar ?
          <Row className='layouts'>
            <Col sm={4}>
              {
                isMobile ?
                  <>
                    <button className='btn menu-btn' onClick={showDrawer}>
                      <MenuFoldOutlined />
                    </button>
                    <Drawer title="Admin Sidebar" className='admin-drawer' width={310} placement="left" onClose={onClose} open={open}>
                      <AdminSideBar />
                    </Drawer>
                  </>
                  :
                  <AdminSideBar />
              }
            </Col>
            <Col sm={20}>
              {props.children}
            </Col>
          </Row>
          :
          props.children
      }

    </div >
  )
}
