import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Link } from 'react-router-dom';
import { ErrorMessage, SuccessMessage } from '../../../components/Messages/messages';
import { Loading } from '../../../components/Loading/Loading';
import ReactQuill from 'react-quill';
import { Layout } from '../../../layout/Layout';
import EditorToolbar, { formats, modules } from '../../../components/QuillEditor';


export const UpdateContactForm = (props) => {
    const [file, setFile] = useState('');
    const [blogPicture, setBlogPicture] = useState({});
    const [loading, setLoading] = useState(false);
    const [description, setDescription] = useState("");
    const [data, setData] = useState({
        title: '',
        subTitle: "",
        btnText: ""
    });

    const { title, subTitle, btnText } = data;

    /***********************************************onChange *******************************************/
    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    }

    const handleImageChange = (e) => {
        setFile(e.target.files[0])
    }

    const getData = async () => {
        await axios.get(`https://bazrbuzz.herokuapp.com/api/contact`).then(res => {
            if (res.statusText === "OK") {
                setBlogPicture(res.data[0].picture);
                setData(res.data[0]);
                setDescription(res.data[0]?.description);
            } else {
                ErrorMessage(res.data.errorMessage);
            }
        })
    }

    /************************************************ Submit **********************************************/

    const submitHandler = (e) => {
        e.preventDefault();
        setLoading(true);
        let data = new FormData();
        data.append('title', title);
        data.append('subTitle', subTitle);
        data.append('description', description);
        data.append('btnText', btnText);
        if (file) {
            data.append('file', file);
        }
        axios.post(`https://bazrbuzz.herokuapp.com/api/contact/update`, data, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('token')
            }
        }).then(res => {
            if (res.statusText === "OK") {
                setLoading(false);
                SuccessMessage(res.data.successMessage);
            }
            else {
                ErrorMessage(res.data.errorMessage);
            }
        })
    }


    useEffect(() => {
        getData();
        return () => {
        }
    }, []);

    console.log(description)


    return (
        <Layout sidebar>
            <div className='w-100 p-4 blogs-form mb-5' style={{ marginTop: '10px', paddingTop: '47px', background: '#FFFFFF', boxShadow: '10px 10px 30px rgba(197, 200, 213, 0.76)', borderRadius: '20px' }}>
                {
                    loading
                        ?
                        <Loading />
                        :
                        <div>
                            <form onSubmit={submitHandler}>
                                <div className='d-flex justify-content-between'>
                                    <div>
                                        <h1 className='mb-5'>Update Contact Details</h1>
                                    </div>
                                    <div>
                                        <Link to='/admin/blogs' type="button" className="btn-close" aria-label="Close"></Link>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Contact Title</label>
                                    <input type="text" required className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id='title' value={title} name='title' placeholder="Enter Your Contact Title" onChange={handleChange} />
                                </div>
                                <div className="form-group">
                                    <label>Contact Sub Title</label>
                                    <input type="text" required className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id='subTitle' value={subTitle} name='subTitle' placeholder="Enter Your Contact Sub Title" onChange={handleChange} />
                                </div>
                                <div className='form-group'>
                                    <label>Contact Profile Picture</label><br />
                                    <input type="file" name='file' onChange={handleImageChange} />
                                    <div className='form-group mt-5 mb-5'>
                                        {
                                            file ?
                                                file && file?.type.slice(0, 5) === "video" ?
                                                    <video controls src={file !== '' ? URL.createObjectURL(file) : ''} className='w-100 h-100' />
                                                    :
                                                    <img src={file !== '' ? URL.createObjectURL(file) : ''} alt={title} className='w-100 h-100' />
                                                :
                                                blogPicture &&
                                                    blogPicture?.type === "video" ?
                                                    <video src={blogPicture.url} />
                                                    :
                                                    <img src={blogPicture.url} alt={title} />
                                        }
                                    </div>
                                </div>
                                <div className='form-group'>
                                    <label>Contact Description</label><br />
                                    <EditorToolbar />
                                    <ReactQuill formats={formats} modules={modules} theme="snow" placeholder="Contact Description" value={description} onChange={(data) => setDescription(data)} />
                                </div>
                                <div className="form-group">
                                    <label>Contact Button Text</label>
                                    <input type="text" required className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id='subTitle' value={btnText} name='btnText' placeholder="Enter Your Contact Button Text" onChange={handleChange} />
                                </div>
                                <button type="submit" size='large' className="btn btn-dark w-100 mt-4">Submit</button>
                            </form>
                        </div>
                }
            </div>
        </Layout>
    )
}
