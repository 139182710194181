/* eslint-disable jsx-a11y/alt-text */
//
import { useEffect, useState } from 'react';
import axios from 'axios';
import { ErrorMessage } from '../../components/Messages/messages';
import BlogCard from '../../components/BlogCard';

function Education() {
  const blogId = window.location.pathname.split("blog/")[1];
  const [data, setData] = useState({});
  const [blogs, setBlogs] = useState([]);

  const getData = async () => {
    await axios.get(`https://bazrbuzz.herokuapp.com/api/blogs/blog/${blogId}`).then(res => {
      if (res.statusText === "OK") {
        setData(res.data);
      } else {
        ErrorMessage(res.data.errorMessage);
      }
    })
  }


  const getAllData = async () => {
    await axios.get(`https://bazrbuzz.herokuapp.com/api/blogs`).then(res => {
      if (res.statusText === "OK") {
        console.log(res)
        setData(res.data[0]);
      } else {
        ErrorMessage(res.data.errorMessage);
      }
    })
  }

  const getRecentBlogs = async () => {
    await axios.get(`https://bazrbuzz.herokuapp.com/api/blogs/recent`).then(res => {
      if (res.statusText === "OK") {
        setBlogs(res.data);
      } else {
        ErrorMessage(res.data.errorMessage);
      }
    })
  }


  useEffect(() => {
    window.scrollTo(0, 0)
    blogId !== "undefined" ? getData() : getAllData();
    getRecentBlogs();
    return () => {
    }
  }, []);

  return (
    <div className="dark font-Rubic">
      <div className="dark:bg-black-700 min-h-screen">
        <div className="lg:container lg:mx-auto lg:m-5 lg:mt-0 mt-0 p-5 lg:p-0">
          <h6 className="text-green-800 font-bold pt-5">{data.title}</h6>
          <p className="text-2xl text-white">{data.subTitle}</p>
          <div className="bg-indigo-300 rounded-xl mt-2">
            {
              data?.picture?.type === "video" ?
                <video controls className="object-cover h-70 w-full rounded-lg" style={{ maxHeight: "80vh" }} src={data?.picture?.url} />
                :
                <img className="object-cover h-70 w-full rounded-lg" style={{ maxHeight: "80vh" }} alt={data.title} src={data?.picture?.url} />
            }
          </div>
        </div>
        <div className="container mx-auto  mt-7 p-5 sm:p-0  ">
          <div className="">
            <div className="text-grey-40">
              <div className='text-grey-40 ql-editor' dangerouslySetInnerHTML={{ __html: data?.description }}></div>
            </div>
          </div>
          <div className="mt-12">
            <div>
              <h1 className="text-2xl text-white pb-5">Latest Blogs</h1>
              <div className='flex gap-6 justify-center sm:justify-start flex-wrap'>
                {
                  blogs?.length > 0 && blogs.filter(d => d._id !== blogId).map(b => {
                    return (
                      <BlogCard data={b} />
                    )
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

}
export default Education;