import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { ErrorMessage } from '../../../components/Messages/messages';
import Papa from "papaparse";
import { Layout } from '../../../layout/Layout';
import { Loading } from '../../../components/Loading/Loading';
import { Alert } from 'antd';
import moment from 'moment';


export const CSVUpload = () => {
  const [file, setFile] = useState();
  const [loading, setLoading] = useState(false);
  const [csvOneData, setCsvOneData] = useState([]);
  const [csvTwoData, setCsvTwoData] = useState([]);

  const getData = async () => {
    await axios.get(`https://bazrbuzz.herokuapp.com/api/csv/ten`).then(res => {
      if (res.statusText === "OK") {
        setCsvOneData(res.data);
        console.log(res.data)
      } else {
        ErrorMessage(res.data.errorMessage);
      }
    })
  }

  const getDataTwo = async () => {
    await axios.get(`https://bazrbuzz.herokuapp.com/api/csv/ten-2`).then(res => {
      if (res.statusText === "OK") {
        setCsvTwoData(res.data);
      } else {
        ErrorMessage(res.data.errorMessage);
      }
    })
  }

  useEffect(() => {
    getData();
    getDataTwo();
    return () => {
    }
  }, []);

  const fileReader = new FileReader();

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleOnSubmitForCSV1 = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (file) {
      fileReader.onload = function (event) {
        Papa.parse(event.target.result, {
          header: true,
          skipEmptyLines: true,
          complete: function (results) {
            axios.post(`https://bazrbuzz.herokuapp.com/api/csv/upload`, { data: results.data, fileName: "csv", uploadTime: moment().format('MMMM Do YYYY, h:mm:ss a') }, {
              headers: {
                authorization: 'Bearer ' + localStorage.getItem('token')
              }
            }).then(res => {
              if (res.statusText === "OK") {
                setCsvOneData(res.data);
                document.location.reload();
              } else {
                ErrorMessage(res.data.errorMessage);
              }
            })
          },
        });
      };
      fileReader.readAsText(file);
      setLoading(false);
    }
  };

  const handleOnSubmitForCSV2 = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (file) {
      fileReader.onload = function (event) {
        Papa.parse(event.target.result, {
          header: true,
          skipEmptyLines: true,
          complete: function (results) {
            axios.post(`https://bazrbuzz.herokuapp.com/api/csv/upload-2`, { data: results.data, fileName: "csv", uploadTime: moment().format('MMMM Do YYYY, h:mm:ss a') }, {
              headers: {
                authorization: 'Bearer ' + localStorage.getItem('token')
              }
            }).then(res => {
              if (res.statusText === "OK") {
                setCsvTwoData(res.data);
                document.location.reload();
              } else {
                ErrorMessage(res.data.errorMessage);
              }
            })
          },
        });
      };
      fileReader.readAsText(file);
      setLoading(false);
    }
  };

  return (
    <Layout sidebar>
      <div className='CSV mb-10'>
        <div style={{ textAlign: "center", marginTop: "100px" }}>
          <h1>Upload articles_tech_data.csv</h1>
          {
            loading ?
              <Loading />
              :
              <form>
                <input
                  type={"file"}
                  name="file"
                  id={"csvFileInput"}
                  accept={".csv"}
                  onChange={handleOnChange}
                />

                <button
                  className='mt-4'
                  onClick={(e) => {
                    handleOnSubmitForCSV1(e);
                  }}
                >
                  IMPORT CSV
                </button>
              </form>
          }
        </div>
        <div className='mt-10'>
          {
            csvOneData.length > 0 &&
            <Alert
              message="articles_tech_data.csv uploaded"
              description={`Uploaded previously on ${csvOneData[0]?.uploadTime}`}
              type="success"
              showIcon
            />
          }
        </div>
        <div style={{ textAlign: "center", marginTop: "100px" }}>
          <h1>Upload out_from_funda.csv</h1>
          {
            loading ?
              <Loading />
              :
              <form>
                <input
                  type={"file"}
                  name="file"
                  id={"csvFileInput"}
                  accept={".csv"}
                  onChange={handleOnChange}
                />

                <button
                  className='mt-4'
                  onClick={(e) => {
                    handleOnSubmitForCSV2(e);
                  }}
                >
                  IMPORT CSV
                </button>
              </form>
          }
        </div>
        <div className='mt-10'>
          {
            csvTwoData.length > 0 &&
            <Alert
              message="out_from_funda.csv uploaded"
              description={`Uploaded previously on ${csvOneData[0]?.uploadTime}`}
              type="success"
              showIcon
            />
          }
        </div>
      </div>
    </Layout>
  )
}
