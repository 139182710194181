import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { ErrorMessage, SuccessMessage } from '../../../components/Messages/messages';
import { Loading } from '../../../components/Loading/Loading';
import { Layout } from '../../../layout/Layout';
import ReactQuill from 'react-quill';
import EditorToolbar, { formats, modules } from '../../../components/QuillEditor';

export const UpdateFooterLinks = (props) => {
    const navigate = useNavigate();
    const linkId = window?.location.search.split("?")[1];

    const [description, setDescription] = useState("");
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        text: '',
        link: "",
        type: "link",
    });

    const { text, link, type } = data;

    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    }

    console.log(type);

    const getData = async () => {
        await axios.get(`https://bazrbuzz.herokuapp.com/api/footer/get/${linkId}`, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('token')
            }
        }).then(res => {
            if (res.statusText === "OK") {
                setData(res.data);
                setDescription(res.data?.description);
            } else {
                ErrorMessage(res.data.errorMessage);
            }
        })
    }

    /************************************************ Submit **********************************************/

    const submitHandler = (e) => {
        e.preventDefault();
        setLoading(true);
        axios.post(`https://bazrbuzz.herokuapp.com/api/footer/${linkId ? "update/" + linkId : "add"}`, { text, link, type, description }, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('token')
            }
        }).then(res => {
            setLoading(false);
            if (res.statusText === "OK") {
                SuccessMessage(res.data.successMessage);
                navigate("/admin/footer")
            }
            else {
                ErrorMessage(res.data.errorMessage);
            }
        })
    }


    useEffect(() => {
        linkId && getData();
        return () => {
        }
    }, []);


    return (
        <Layout sidebar>
            <div className='w-100 p-4 blogs-form mb-5' style={{ marginTop: '10px', paddingTop: '47px', background: '#FFFFFF', boxShadow: '10px 10px 30px rgba(197, 200, 213, 0.76)', borderRadius: '20px' }}>
                {
                    loading
                        ?
                        <Loading />
                        :
                        <div>
                            <form onSubmit={submitHandler}>
                                <div className='d-flex justify-content-between'>
                                    <div>
                                        <h1 className='mb-5'>Update Footer Details</h1>
                                    </div>
                                    <div>
                                        <Link to='/admin/blogs' type="button" className="btn-close" aria-label="Close"></Link>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Text</label>
                                    <input type="text" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id='text' value={text} name='text' placeholder="Enter text" onChange={handleChange} />
                                </div>
                                <div className="form-group">
                                    <label>Link</label>
                                    <input type="text" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id='link' value={link} name='link' placeholder="Enter link" onChange={handleChange} />
                                </div>
                                <div className="form-group">
                                    <div className="mb-3 xl:w-96">
                                        <select value={type} name='type' onChange={handleChange} className="form-select appearance-none block w-full px-3 py-1.5 p-2 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" aria-label="Default select example">
                                            <option value="link">Link</option>
                                            <option value="email">Email</option>
                                            <option value="phone">Phone</option>
                                        </select>
                                    </div>
                                </div>
                                {
                                    type === "link" &&
                                    <div className="form-group">
                                        <label>Description</label><br />
                                        <EditorToolbar />
                                        <ReactQuill formats={formats} modules={modules} theme="snow" placeholder="Description" value={description} onChange={setDescription} />
                                    </div>
                                }
                                <button type="submit" size='large' className="btn btn-dark w-100 mt-4">Submit</button>
                            </form>
                        </div>
                }
            </div>
        </Layout>
    )
}
