import React from 'react'

const BlogCard = ({ data }) => {
    return (
        <div className="rounded-lg bg-black-900 pb-2 mb-5 blogCard">
            <div className="bg-indigo-300 rounded-xl ">
                {
                    data?.picture?.url.split("/upload/")[0] === "https://res.cloudinary.com/saeedahmed/video" ?
                        <video className="object-cover h-40 w-full rounded-lg" src={data?.picture?.url} />
                        :
                        <img className="object-cover h-40 w-full rounded-lg" alt={data.title} src={data?.picture?.url} />
                }
            </div>
            <div className="p-2 overflow-hidden ">
                <h3 className="text-green-800  text-sm pt-3">{data.title}</h3>
                <h3 className="text-md text-white pt-0 mt-2" style={{ minHeight: "100px" }}>{data.subTitle}</h3>
                <div className="w-full mt-5">
                    <a href={"/blog/" + data._id} className="border text-sm block text-center clear-left pt-2 pb-2 w-full border-green-500 text-green-500 rounded">
                        Read More
                    </a>
                </div>
            </div>
        </div>
    )
}

export default BlogCard
