import { useEffect } from 'react';
import './newsticker.css';
import React from 'react';
import { useState } from 'react';

function NewsTicker({ dataBuy, dataSell }) {
  const [isRunning, setIsRunning] = useState(true);

  useEffect(() => {

  }, [isRunning]);

  console.log(dataBuy[1])

  return (
    <div className="p-0 m-0 flex items-stretch items-center justify-center left-0 right-0 top-0 ">
      <div className="wrapper z-0 pb-0">
        <div className="marquee p-1 space-x-0 m-auto">
          {
            dataBuy?.length > 0 && dataBuy.map(d => {
              return (
                <section className="inline-block p-1">
                  <div className="ml-2 ">
                    <span className="text-grey-50 text-md" style={{fontSize: "17px"}}><span className="mr-1">⬤</span>  {d.title}</span>
                  </div>
                </section>
              )
            })
          }
          {
            dataSell?.length > 0 && dataSell.map(d => {
              return (
                <section className="inline-block p-1 newsTickter">
                  <div className="ml-2 ">
                    <span className="text-grey-50"><span className="mr-1">⬤</span>  {d.title}</span>
                  </div>
                </section>
              )
            })
          }
        </div>
      </div>
    </div>
  );
}

export default NewsTicker;