import React, { useState } from 'react';
import { Form, Input } from 'antd';
import axios from 'axios';
import { ContactsOutlined, KeyOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Loading } from '../../components/Loading/Loading';
import { ErrorMessage, SuccessMessage } from '../../components/Messages/messages';
import "./Auth.css"

const Signup = (props) => {
  const [loading, setLoading] = useState(false);

  const [userData, setUserData] = useState({
    fullName: '',
    email: '',
    username: '',
    password: '',
    confimPassword: '',
  });

  const handleChange = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value
    })
  }

  const onFinish = async (e) => {
    window.scrollTo(0, 0);
    setLoading(true);
    await axios.post(`https://bazrbuzz.herokuapp.com/api/users/signup`, userData).then(res => {
      setLoading(false);
      if (res.statusText === "OK") {
        SuccessMessage(res.data.successMessage);
        setTimeout(() => {
          props.history.push('/login');

        }, 2000);
      }
      else if (res.status === 201) {
        ErrorMessage(res.data.errorMessage);
      }
      else {
        ErrorMessage(res.data.errorMessage);
      }
    })
  };

  return (
    <div>
      <div className='auth-form'>
        <div className='auth-form-inner'>
          <div className='mb-3 logo'>
            <h1>BAZRBUZZ</h1>
          </div>
          {
            loading
              ?
              <div>
                <Loading />
              </div>
              :
              <Form
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
              >
                <div className="floating-label-group">
                  <Form.Item
                    name="Full Name"
                    rules={[{ required: true, message: 'Please input your Full Name!' }]}
                  >
                    <Input name='fullName' onChange={handleChange} size='small' placeholder="Full Name*" prefix={<ContactsOutlined />} />
                  </Form.Item>
                </div>
                <div className="floating-label-group">
                  <Form.Item
                    name="username"
                    rules={[{ required: true, message: 'Please input your username!' }]}
                  >
                    <Input name='username' type='text' onChange={handleChange} size="small" placeholder="Username*" prefix={<UserOutlined />} />
                  </Form.Item>
                </div>
                <div className="floating-label-group">
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        type: 'email',
                        message: 'The input is not valid E-mail!',
                      },
                      {
                        required: true,
                        message: 'Please input your E-mail!',
                      },
                    ]}
                  >
                    <Input name='email' onChange={handleChange} size='small' placeholder="Email*" prefix={<MailOutlined />} />
                  </Form.Item>
                </div>
                <div className="floating-label-group">
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your password!',
                      },
                    ]}
                    hasFeedback
                  >
                    <Input.Password type='password' name='password' onChange={handleChange} size="small" placeholder="Password*" prefix={<KeyOutlined />} />
                  </Form.Item>
                </div>
                <div className="floating-label-group">
                  <Form.Item
                    name="confirm"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: 'Please confirm your password!',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('The two passwords that you entered do not match!'));
                        },
                      }),
                    ]}
                  >
                    <Input.Password name='confimPassword' onChange={handleChange} size='small' placeholder="Re-Enter Password*" prefix={<KeyOutlined />} />
                  </Form.Item>
                </div>
                <button type='submit' className='btn'>
                  Create Account
                </button>
              </Form>
          }
          <div className='mt-2 text-center'>
            <p>
              Already Have Account? <Link to='/login' className='text-primary fw-bold'>Login</Link>
            </p>
          </div>

        </div>
      </div>
    </div>
  );
};


export default Signup;