import { registerUpdateLifecycle } from "echarts";
import React, { useEffect, useState } from "react";
import BalanceSheet from "./BalanceSheet";
import CashFlow from "./CashFlow";
import Fundamentals from "./Fundamentals";
import FundamentalsOverview from "./FundamentalsOverview";
import IncomeStatement from "./IncomeStatement";
import Overview from "./Overview";
import Technicals from "./Technicals";

const TabContent = props => {
  const { tabSelected } = props;
  // const [loading, setLoading] = useState(true);
  // let { tabSelected } = props;

  props?.selectedCSV2Stock && localStorage.setItem("selectedCSV2Stock", JSON.stringify(props?.selectedCSV2Stock))

  const selectedCSV2Stock = JSON.parse(localStorage.getItem("selectedCSV2Stock"));
  // const selectedCSV2Stock = selectedCSV2Stock;

  const selectedStock = props.selectedStock;


  // useEffect(() => {
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 2000);

  //   return () => {

  //   }
  // }, [])


  if (tabSelected === 'FundamentalsOverview') {
    return <FundamentalsOverview selectedStock={selectedStock} selectedCSV2Stock={selectedCSV2Stock} />;
  }
  else if (tabSelected === 'Overview') {
    return <Overview selectedStock={selectedStock} selectedCSV2Stock={selectedCSV2Stock} />;
  }

  else if (tabSelected === 'Fundamentals') {
    return <Fundamentals selectedStock={selectedStock} selectedCSV2Stock={selectedCSV2Stock} />;
  }


  else if (tabSelected === 'Technicals') {
    return <Technicals selectedStock={selectedStock} />;
  }


  else if (tabSelected === 'CashFlow') {
    return <CashFlow selectedStock={selectedStock} selectedCSV2Stock={selectedCSV2Stock} />;
  }


  else if (tabSelected === 'IncomeStatement') {
    return <IncomeStatement selectedStock={selectedStock} selectedCSV2Stock={selectedCSV2Stock} />;
  }

  else if (tabSelected === 'BalanceSheet') {
    return <BalanceSheet selectedStock={selectedStock} selectedCSV2Stock={selectedCSV2Stock} />;
  }

};

export default TabContent;