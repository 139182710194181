/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/style-prop-object */
import './App.css';
import 'tw-elements';
import React from "react";
import Dashboard from './pages/Dashboard/Dashboard';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Education from './pages/Education/Education';
import Home from './pages/Home/Home';
import Contact from './pages/Contact/Contact';
import CookiesPolicy from './pages/CookiesPolicy/CookiesPolicy';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse/TermsOfUse';
import Disclaimer from './pages/Disclaimer/Disclaimer';
import Footer from './components/Footer/Footer';
import Login from './pages/Auth/Login';
import Signup from './pages/Auth/Signup';
import 'antd/dist/antd.css';
import { Blogs } from './pages/Admin/Blogs/Blogs';
import 'react-quill/dist/quill.snow.css';
import { CreateBlogsForm } from './pages/Admin/Blogs/CreateBlogsForm';
import { UpdateBlogsForm } from './pages/Admin/Blogs/UpdateBlogsForm';
import { CSVUpload } from './pages/Admin/CSVUpload/CSVUpload';
import Navbar from './components/Navbar/Navbar';
import './index.css';
import { AdminContact } from './pages/Admin/Contact/Contact';
import { UpdateContactForm } from './pages/Admin/Contact/UpdateContactForm';
import { AdminFooter } from './pages/Admin/Footer/FooterLinks';
import { UpdateFooterLinks } from './pages/Admin/Footer/UpdateFooterForm';

function App() {
  return (
    <div className='App'>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/blog/:id" element={<Education />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/cookiespolicy" element={<CookiesPolicy />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/termsofuse" element={<TermsOfUse />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/admin" element={<CSVUpload />} />
          <Route path="/admin/blogs" element={<Blogs />} />
          <Route path="/admin/create-blog" element={<CreateBlogsForm />} />
          <Route path="/admin/update-blog/:id" element={<UpdateBlogsForm />} />
          <Route path="/admin/contact" element={<AdminContact />} />
          <Route path="/admin/data" element={<AdminContact />} />
          <Route path="/admin/update-contact" element={<UpdateContactForm />} />
          <Route path="/admin/footer" element={<AdminFooter />} />
          <Route path="/admin/update-footer" element={<UpdateFooterLinks />} />
          <Route path="*" element={<Home />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
