import React from 'react'
import ReactECharts from 'echarts-for-react';

const Gauge = ({ arrowPointerValue, signal }) => {
    return (
        <div className='mt-10'>
            <ReactECharts
                className="gaugeChart"
                option={{
                    width: 'auto',
                    height: 'auto',
                    radius: ['60%'],
                    itemGap: 0,
                    responsive: false,
                    maintainAspectRatio: false,
                    series: [
                        {
                            type: 'gauge',
                            startAngle: 180,
                            endAngle: 0,
                            min: 0,
                            max: 1,
                            splitNumber: 8,
                            axisLine: {
                                lineStyle: {
                                    width: 5,
                                    color: [
                                        [0.25, '#FF6E76'],
                                        [0.5, '#FDDD60'],
                                        [0.75, '#58D9F9'],
                                        [1, '#7CFFB2']
                                    ]
                                }
                            },
                            pointer: {
                                icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
                                length: '8%',
                                width: 15,
                                offsetCenter: [0, '-60%'],
                                itemStyle: {
                                    color: 'white'
                                }
                            },
                            axisTick: {
                                length: 8,
                                lineStyle: {
                                    color: 'auto',
                                    width: 2
                                }
                            },
                            splitLine: {
                                length: 20,
                                lineStyle: {
                                    color: 'auto',
                                    width: 5
                                }
                            },
                            axisLabel: {
                                color: '#FFFFFF',
                                fontSize: 12,
                                distance: -80,
                                offsetCenter: [0, '0%'],
                                // textStyle: {
                                //     color: "#fff",
                                //     padding: 20,
                                //     bottom: 100,
                                //     paddigTop: 100
                                // },
                                formatter: function (value) {
                                    if (value === 0.875) {
                                        return 'Strong Buy'
                                    }
                                    else if (value === 0.75) {
                                        return 'Buy';
                                    } else if (value === 0.5) {
                                        return 'Neutral';
                                    } else if (value === 0.25) {
                                        return 'Sell';
                                    } else if (value === 0.125) {
                                        return 'Strong Sell';
                                    }
                                    return '';
                                }
                            },
                            title: {
                                offsetCenter: [0, '-20%'],
                                fontSize: 20,
                                textStyle: {
                                    color: "#fff"
                                }
                            },
                            detail: {
                                fontSize: 25,
                                offsetCenter: [0, '0%'],
                                valueAnimation: true,
                                formatter: function (value) {
                                    // return Math.round(value * 100);
                                },
                                color: 'auto'
                            },
                            data: [
                                {
                                    value: arrowPointerValue,
                                    name: signal
                                }
                            ]
                        }
                    ]
                }} />
        </div>
    )
}

export default Gauge
