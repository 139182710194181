import { useEffect, useRef } from 'react';
import './stocksticker.css';
import React from 'react';
import { useState } from 'react';
import playIcon from '../../assets/play.png';
import pauseIcon from '../../assets/pause.png';
import { FormatNumbers } from '../FormatNumber';


function StocksTicker({ data }) {
    const marqueeRef = useRef(null);
    const [isRunning, setIsRunning] = useState(true);

    useEffect(() => {

    }, [isRunning]);


    const handleMouseEnter = () => {
        if (isRunning) {
            setIsRunning(false)
            marqueeRef.current.stop();
        }
    };

    const handleMouseLeave = () => {
        // if (!isRunning) {
        setIsRunning(true);
        marqueeRef.current.start();
        // }
    };

    return (
        <div style={{ backgroundColor: "rgb(63 68 90 / 1)" }} className="p-0 m-0 flex items-stretch items-center justify-center dark:bg-black-500 left-0 right-0 top-0 ">
            <div className=" flex items-center border-r border-r-white pl-4 pr-4">
                <button onClick={() => { setIsRunning(!isRunning); isRunning ? handleMouseEnter() : handleMouseLeave() }} className="w-[23px] h-[23px]">
                    <img alt="" src={isRunning ? pauseIcon : playIcon} className="w-[23px] h-[23px]" />
                </button>
            </div>
            <div className="wrapper  z-0 pb-0">
                <marquee ref={marqueeRef} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                    <div className={`flex text-grey-50 p-1 pb-0 mb-0  space-x-8 m-auto  justify-between dark:bg-black-500`}>
                        {
                            data?.length > 0 && data.map(d => {
                                return (
                                    <section class={`flex-shrink-0 inline-block pb-0 pt-0`} style={{ fontSize: "15px" }}>
                                        <span className={`mr-1 text-green-1000 ${Math.sign(d.change) === -1 ? "sell" : "buy"}`}>{Math.sign(d.change) === -1 ? "▼" : "▲"}</span>
                                        <span className="text-grey-50">{d.name} <span className={`${Math.sign(d.change) === -1 ? "sell" : "buy"}`}>{d.percent_change}%</span> ({FormatNumbers(d.close)})</span>
                                    </section>
                                )
                            })
                        }
                    </div>
                </marquee>
            </div>
        </div >
    );
}

export default StocksTicker;