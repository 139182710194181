/* eslint-disable jsx-a11y/alt-text */
//
import { useEffect, useState } from 'react';
import axios from 'axios';
import { ErrorMessage, SuccessMessage } from '../../components/Messages/messages';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.core.css';

function Contact() {
  const [data, setData] = useState({});
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState('');

  const getData = async () => {
    await axios.get(`https://bazrbuzz.herokuapp.com/api/contact`).then(res => {
      if (res.statusText === "OK") {
        setData(res.data[0]);
      } else {
        ErrorMessage(res.data.errorMessage);
      }
    })
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
    return () => {
    }
  }, []);

  useEffect(() => {

  }, []);

  const handleSubmit = async () => {
    await axios.post("https://bazrbuzz.herokuapp.com/api/contact/mailchimp", { email }).then(res => {
      console.log(res);
      SuccessMessage(res.data.successMessage);
    }).catch(err => {
      console.log(err.response.data?.err?.title)
      ErrorMessage(err.response.data?.err?.title);
    })
  }


  return (
    <div className="dark font-Rubic contactUs">
      <div className="dark:bg-black-800 min-h-screen">
        <div className="md:container md:mx-auto sm:m-5 sm:m-0 mt-0">
          <div className="grid md:grid-cols-1 p-1 pt-12 sm:pt-0">
            <div className="text-center">
              <h1 className="text-white text-5xl space tracking-wide">{data?.title}</h1>
              <h1 className="mt-2 text-white text-5xl space text-green-500">{data?.subTitle}</h1>
              <div className="mt-10 mb-10 ml-10 mr-10 sm:flex sm:justify-center  grid grid-cols-1 sm:grid-cols-2">
                <div className=" sm:w-96 w-full  flex items-center justify-end">
                  <input type="text " placeholder="Enter Email or WhatsApp Number" onChange={(e) => setEmail(e.target.value)} className="focus:bg-white w-full sm:w-80 text-white focus:text-black-900  outline-none rounded-tl-sm rounded-bl-sm  rounded-tr-sm rounded-br-sm bg-black-900  p-2 pl-4 text-sm pr-10 border-t border-l border-b border-white border-r-12 border-r-green-500"></input>
                </div>
                <div className="  w-full sm:w-36 sm:ml-5 mt-5 sm:mt-0  ">
                  <button style={{ paddingTop: '8px ', paddingBottom: '8px' }} onClick={handleSubmit} className="h-fit bg-green-500 pl-3 pr-3 rounded-sm text-sm font-bold">Join the Waitlist</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p-5 pb-12">
          <div className="md:container md:mx-auto sm:m-5 mt-5  ">
            {/*Slider */}
            <div className="grid md:grid-cols-2 mt-4 gap-6">
              <section>
                <div className='ql-editor' dangerouslySetInnerHTML={{ __html: data?.description }}></div>
                <button className="bg-green-800 pt-1.5 pb-1.5 pl-7 pr-7 text-md  rounded-sm mt-0 my-5 font-bold">{data?.btnText}</button>
              </section>
              <section className="">
                <img src={data?.picture?.url} className="object-cover  rounded-md w-full" />
              </section>
            </div>
          </div>
          {/* Slider End */}
        </div>
      </div>
    </div>
  );

}
export default Contact;