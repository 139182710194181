/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/style-prop-object */
import React from 'react';
import { FormatNumbers } from '../FormatNumber';
import Gauge from '../Gauge';

function Technicals({ selectedStock }) {
    let summarySignal = selectedStock?.summary_signal;
    let summaryArrowPointerValue = summarySignal === "Strong Buy" ? 0.875 : summarySignal === "Buy" ? 0.75 : summarySignal === "Neutral" ? 0.5 : summarySignal === "Sell" ? 0.25 : summarySignal === "Strong Sell" && 0.125;

    let trendSignal = selectedStock?.trend_signal;
    let trendArrowPointerValue = trendSignal === "Strong Buy" ? 0.875 : trendSignal === "Buy" ? 0.75 : trendSignal === "Neutral" ? 0.5 : trendSignal === "Sell" ? 0.25 : trendSignal === "Strong Sell" && 0.125;

    let momentumSignal = selectedStock?.momentum_signal;
    console.log(selectedStock?.momentum_signal);
    let momentumArrowPointerValue = momentumSignal === "Strong Buy" ? 0.875 : momentumSignal === "Buy" ? 0.75 : momentumSignal === "Neutral" ? 0.5 : momentumSignal === "Sell" ? 0.25 : momentumSignal === "Strong Sell" && 0.125;

    return (
        <div className="grid md:grid-cols-3 mt-5  ">
            <div className="md:col-span-3 ">
                <div className="dark:bg-black-800 rounded-md pb-3">
                    <div className="overflow-hidden rounded-md ">
                        {/* <div className="text-grey-20  p-4  bg-grey-10">
                            <h4 className="text-sm text-white mb-3 font-bold">Your technical analysis summary for {selectedStock?.name}</h4>
                            <p className="text-sm">This gauge displays a real-time technical analysis overview for your selected timeframe. The summary of {selectedStock?.name} is based on the most popular technical indicators, such as Momentum, Trend and Pivots.</p>
                        </div> */}
                        <div className="grid md:grid-cols-1 mt-4">
                            <div className="w-full h-auto p-1 ">
                                <h1 className="text-white text-center mb-3 text-lg text-white my-5">Summary</h1>
                                <div id="main" className="" style={{ width: '350px', height: '180px', border: '', padding: '0px', bottom: 0, margin: 'auto' }}>
                                    <Gauge arrowPointerValue={summaryArrowPointerValue} signal={summarySignal} />
                                </div>
                            </div>
                        </div>
                        <div className="grid grid-cols-1 sm:grid-cols-2">
                            <div className="">
                                <div className="w-full h-auto p-1 ">
                                    <h1 className="text-white text-center mb-3 text-lg text-white my-12">Trend</h1>
                                    <div id="main" className="" style={{ width: '350px', height: '180px', border: '', padding: '0px', bottom: 0, margin: 'auto' }}>
                                        <Gauge arrowPointerValue={trendArrowPointerValue} signal={summarySignal} />
                                    </div>
                                </div>
                            </div>
                            <div className="">
                                <div className="w-full h-auto p-1 ">
                                    <h1 className="text-white text-center mb-3 text-lg text-white my-12">Momentum</h1>
                                    <div id="main" className="" style={{ width: '350px', height: '180px', border: '', padding: '0px', bottom: 0, margin: 'auto' }}>
                                        <Gauge arrowPointerValue={momentumArrowPointerValue} signal={summarySignal} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="grid grid-cols-1 sm:grid-cols-2 mt-8">
                            <div className="">
                                <div className=" p-3">
                                    <h1 className="text-white p-2 text-grey-40 font-bold">Trend</h1>
                                    <table class="w-full  text-md  ">
                                        <thead>
                                            <th className="p-2 text-sm text-grey-110 text-left font-normal">Name</th>
                                            <th className="p-2 text-sm text-grey-110 text-left font-normal">Value</th>
                                            <th className="p-2  text-sm text-grey-110 text-left font-normal">Action</th>
                                        </thead>
                                        <tbody className="p-2">
                                            <tr className="">
                                                <td className="border-b border-b-grey-15 p-2 text-grey-40">Exponential Moving Average (9)</td>
                                                <td className="border-b border-b-grey-15 p-2 text-grey-40 " >{FormatNumbers(selectedStock?.ema9_0_ema)}	</td>
                                                <td className={`border-b border-b-grey-15 p-2 ${selectedStock?.ema_9_signal?.replace(/\s+/g, '-').toLowerCase()}`}>{selectedStock?.ema_9_signal}</td>
                                            </tr>
                                            <tr className="">
                                                <td className="border-b border-b-grey-15 p-2 text-grey-40">Exponential Moving Average (14)</td>
                                                <td className="border-b border-b-grey-15 p-2 text-grey-40 " >{FormatNumbers(selectedStock?.ema14_0_ema)}	</td>
                                                <td className={`border-b border-b-grey-15 p-2 ${selectedStock?.ema_14_signal?.replace(/\s+/g, '-').toLowerCase()}`}>{selectedStock?.ema_14_signal}</td>
                                            </tr>
                                            <tr className="">
                                                <td className="border-b border-b-grey-15 p-2 text-grey-40">Simple Moving Average (14)</td>
                                                <td className="border-b border-b-grey-15 p-2 text-grey-40 " >{FormatNumbers(selectedStock?.ema14_0_ema)}	</td>
                                                <td className={`border-b border-b-grey-15 p-2 ${selectedStock?.ema_14_signal?.replace(/\s+/g, '-').toLowerCase()}`}>{selectedStock?.ema_14_signal}</td>
                                            </tr>
                                            <tr className="">
                                                <td className="border-b border-b-grey-15 p-2 text-grey-40">Exponential Moving Average (30)</td>
                                                <td className="border-b border-b-grey-15 p-2 text-grey-40 " >{FormatNumbers(selectedStock?.ema30_0_ema)}	</td>
                                                <td className={`border-b border-b-grey-15 p-2 ${selectedStock?.ema_30_signal?.replace(/\s+/g, '-').toLowerCase()}`}>{selectedStock?.ema_30_signal}</td>
                                            </tr>
                                            <tr className="">
                                                <td className="border-b border-b-grey-15 p-2 text-grey-40">Exponential Moving Average (50)</td>
                                                <td className="border-b border-b-grey-15 p-2 text-grey-40 " >{FormatNumbers(selectedStock?.ema50_0_ema)}	</td>
                                                <td className={`border-b border-b-grey-15 p-2 ${selectedStock?.ema_50_signal?.replace(/\s+/g, '-').toLowerCase()}`}>{selectedStock?.ema_50_signal}</td>
                                            </tr>
                                            <tr className="">
                                                <td className="border-b border-b-grey-15 p-2 text-grey-40">Simple Moving Average (50)</td>
                                                <td className="border-b border-b-grey-15 p-2 text-grey-40 " >{FormatNumbers(selectedStock?.sma50_0_sma)}	</td>
                                                <td className={`border-b border-b-grey-15 p-2 ${selectedStock?.sma_50_signal?.replace(/\s+/g, '-').toLowerCase()}`}>{selectedStock?.sma_50_signal}</td>
                                            </tr>
                                            <tr className="">
                                                <td className="border-b border-b-grey-15 p-2 text-grey-40">Exponential Moving Average (100)</td>
                                                <td className="border-b border-b-grey-15 p-2 text-grey-40 " >{FormatNumbers(selectedStock?.ema100_0_ema)}	</td>
                                                <td className={`border-b border-b-grey-15 p-2 ${selectedStock?.ema_100_signal?.replace(/\s+/g, '-').toLowerCase()}`}>{selectedStock?.ema_100_signal}</td>
                                            </tr>
                                            <tr className="">
                                                <td className="border-b border-b-grey-15 p-2 text-grey-40">Exponential Moving Average (200)</td>
                                                <td className="border-b border-b-grey-15 p-2 text-grey-40 " >{FormatNumbers(selectedStock?.ema200_0_ema)}	</td>
                                                <td className={`border-b border-b-grey-15 p-2 ${selectedStock?.ema_200_signal?.replace(/\s+/g, '-').toLowerCase()}`}>{selectedStock?.ema_200_signal}</td>
                                            </tr>
                                            <tr className="">
                                                <td className="border-b border-b-grey-15 p-2 text-grey-40">Simple Moving Average (200)</td>
                                                <td className="border-b border-b-grey-15 p-2 text-grey-40 " >{FormatNumbers(selectedStock?.sma200_0_sma)}	</td>
                                                <td className={`border-b border-b-grey-15 p-2 ${selectedStock?.sma_200_signal?.replace(/\s+/g, '-').toLowerCase()}`}>{selectedStock?.sma_200_signal}</td>
                                            </tr>
                                            <tr className="">
                                                <td className="border-b border-b-grey-15 p-2 text-grey-40">Triple Exponential Moving Average (14)</td>
                                                <td className="border-b border-b-grey-15 p-2 text-grey-40 " >{FormatNumbers(selectedStock?.t3ma14_0_t3ma)}	</td>
                                                <td className={`border-b border-b-grey-15 p-2 ${selectedStock?.t3ma_14_signal?.replace(/\s+/g, '-').toLowerCase()}`}>{selectedStock?.t3ma_14_signal}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="">
                                <div className=" p-3">
                                    <h1 className="text-white p-2 text-grey-40 font-bold">Momentum</h1>
                                    <table class="w-full text-md">
                                        <thead>
                                            <th className="p-2 text-sm text-grey-110 text-left font-normal">Name</th>
                                            <th className="p-2 text-sm text-grey-110 text-left font-normal">Value</th>
                                            <th className="p-2  text-sm text-grey-110 text-left font-normal">Action</th>
                                        </thead>
                                        <tbody className="p-2">
                                            <tr className="">
                                                <td className="border-b border-b-grey-15 p-2 text-grey-40">Momentum (9)</td>
                                                <td className="border-b border-b-grey-15 p-2 text-grey-40 " >{selectedStock?.mom9_0_mom}</td>
                                                <td className={`border-b border-b-grey-15 p-2 ${selectedStock?.mom_9_signal?.replace(/\s+/g, '-').toLowerCase()}`}>{selectedStock?.mom_9_signal}</td>
                                            </tr>
                                            <tr className="">
                                                <td className="border-b border-b-grey-15 p-2 text-grey-40">Relative Strength Index (9)</td>
                                                <td className="border-b border-b-grey-15 p-2 text-grey-40 " >{selectedStock?.rsi9_0_rsi}</td>
                                                <td className={`border-b border-b-grey-15 p-2 ${selectedStock?.rsi_9_signal?.replace(/\s+/g, '-').toLowerCase()}`}>{selectedStock?.rsi_9_signal}</td>
                                            </tr>
                                            <tr className="">
                                                <td className="border-b border-b-grey-15 p-2 text-grey-40">Relative Strength Index (14)</td>
                                                <td className="border-b border-b-grey-15 p-2 text-grey-40 " >{selectedStock?.rsi14_0_rsi}</td>
                                                <td className={`border-b border-b-grey-15 p-2 ${selectedStock?.stoch14_slow_k_signal?.replace(/\s+/g, '-').toLowerCase()}`}>{selectedStock?.rsi_14_signal}</td>
                                            </tr>
                                            <tr className="">
                                                <td className="border-b border-b-grey-15 p-2 text-grey-40">Stochastic %K (14, 3, 1)</td>
                                                <td className="border-b border-b-grey-15 p-2 text-grey-40 " >{selectedStock?.stoch14_0_slow_k}</td>
                                                <td className={`border-b border-b-grey-15 p-2 ${selectedStock?.stoch14_slow_k_signal?.replace(/\s+/g, '-').toLowerCase()}`}>{selectedStock?.stoch14_slow_k_signal}</td>
                                            </tr>
                                            <tr className="">
                                                <td className="border-b border-b-grey-15 p-2 text-grey-40">Commodity Channel Index (20)</td>
                                                <td className="border-b border-b-grey-15 p-2 text-grey-40 " >{selectedStock?.cci20_0_cci}</td>
                                                <td className={`border-b border-b-grey-15 p-2 ${selectedStock?.cci_20_signal?.replace(/\s+/g, '-').toLowerCase()}`}>{selectedStock?.cci_20_signal}</td>
                                            </tr>
                                            <tr className="">
                                                <td className="border-b border-b-grey-15 p-2 text-grey-40">MACD Level (12, 9, 26)</td>
                                                <td className="border-b border-b-grey-15 p-2 text-grey-40 " >{selectedStock?.macd9_0_macd}</td>
                                                <td className={`border-b border-b-grey-15 p-2 ${selectedStock?.macd9_macd?.replace(/\s+/g, '-').toLowerCase()}`}>{selectedStock?.macd9_macd}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {/* <div className="grid grid-cols-1">
                            <div className=" p-2 w-full overflow-x-auto">
                                <h1 className="text-white p-2 text-grey-40 font-bold">PIVOTS</h1>
                                <table class="w-full  text-md overflow-x-auto ">
                                    <thead>
                                        <th className="p-2 text-sm text-grey-110 text-left font-normal">Pivot</th>
                                        <th className="p-2 text-sm text-grey-110 text-left font-normal">Classic</th>
                                        <th className="p-2  text-sm text-grey-110 text-left font-normal">Fibonacci</th>
                                        <th className="p-2  text-sm text-grey-110 text-left font-normal">Camarilla</th>
                                        <th className="p-2  text-sm text-grey-110 text-left font-normal">Woodie</th>
                                        <th className="p-2  text-sm text-grey-110 text-left font-normal">DM</th>
                                    </thead>
                                    <tbody className="p-2">
                                        <tr className="">
                                            <td className="border-b border-b-grey-15 p-2 text-grey-40">53</td>
                                            <td className="border-b border-b-grey-15 p-2 text-grey-40 " >30.52	</td>
                                            <td className="border-b border-b-grey-15 p-2 text-grey-40 " >30.52	</td>
                                            <td className="border-b border-b-grey-15 p-2 text-grey-40 " >30.52	</td>
                                            <td className="border-b border-b-grey-15 p-2 text-grey-40 " >30.52	</td>
                                            <td className="border-b border-b-grey-15 p-2 text-grey-40 " >--	</td>

                                        </tr>
                                        <tr className="">
                                            <td className="border-b border-b-grey-15 p-2 text-grey-40">53</td>
                                            <td className="border-b border-b-grey-15 p-2 text-grey-40 " >30.52</td>
                                            <td className="border-b border-b-grey-15 p-2 text-grey-40 " >30.52</td>
                                            <td className="border-b border-b-grey-15 p-2 text-grey-40 " >30.52</td>
                                            <td className="border-b border-b-grey-15 p-2 text-grey-40 " >30.52</td>
                                            <td className="border-b border-b-grey-15 p-2 text-grey-40 " >15.7</td>

                                        </tr>
                                        <tr className="">
                                            <td className="border-b border-b-grey-15 p-2 text-grey-40">53</td>
                                            <td className="border-b border-b-grey-15 p-2 text-grey-40 " >30.52</td>
                                            <td className="border-b border-b-grey-15 p-2 text-grey-40 " >30.52</td>
                                            <td className="border-b border-b-grey-15 p-2 text-grey-40 " >30.52</td>
                                            <td className="border-b border-b-grey-15 p-2 text-grey-40 " >30.52</td>
                                            <td className="border-b border-b-grey-15 p-2 text-grey-40 " >180.8</td>

                                        </tr>
                                        <tr className="">
                                            <td className="border-b border-b-grey-15 p-2 text-grey-40">53</td>
                                            <td className="border-b border-b-grey-15 p-2 text-grey-40 " >30.52</td>
                                            <td className="border-b border-b-grey-15 p-2 text-grey-40 " >30.52</td>
                                            <td className="border-b border-b-grey-15 p-2 text-grey-40 " >30.52</td>
                                            <td className="border-b border-b-grey-15 p-2 text-grey-40 " >30.52</td>
                                            <td className="border-b border-b-grey-15 p-2 text-grey-40 " >--</td>

                                        </tr>
                                        <tr className="">
                                            <td className="border-b border-b-grey-15 p-2 text-grey-40">53</td>
                                            <td className="border-b border-b-grey-15 p-2 text-grey-40 " >30.52</td>
                                            <td className="border-b border-b-grey-15 p-2 text-grey-40 " >30.52</td>
                                            <td className="border-b border-b-grey-15 p-2 text-grey-40 " >30.52</td>
                                            <td className="border-b border-b-grey-15 p-2 text-grey-40 " >30.52</td>
                                            <td className="border-b border-b-grey-15 p-2 text-grey-40 " >--</td>

                                        </tr>
                                        <tr className="">
                                            <td className="border-b border-b-grey-15 p-2 text-grey-40">53</td>
                                            <td className="border-b border-b-grey-15 p-2 text-grey-40 " >30.52</td>
                                            <td className="border-b border-b-grey-15 p-2 text-grey-40 " >30.52</td>
                                            <td className="border-b border-b-grey-15 p-2 text-grey-40 " >30.52</td>
                                            <td className="border-b border-b-grey-15 p-2 text-grey-40 " >30.52</td>
                                            <td className="border-b border-b-grey-15 p-2 text-grey-40 " >--</td>

                                        </tr>
                                        <tr className="">
                                            <td className="border-b border-b-grey-15 p-2 text-grey-40">53</td>
                                            <td className="border-b border-b-grey-15 p-2 text-grey-40 " >30.52</td>
                                            <td className="border-b border-b-grey-15 p-2 text-grey-40 " >30.52</td>
                                            <td className="border-b border-b-grey-15 p-2 text-grey-40 " >30.52</td>
                                            <td className="border-b border-b-grey-15 p-2 text-grey-40 " >30.52</td>
                                            <td className="border-b border-b-grey-15 p-2 text-grey-40 " >--</td>

                                        </tr>
                                        <tr className="">
                                            <td className="border-b border-b-grey-15 p-2 text-grey-40">53</td>
                                            <td className="border-b border-b-grey-15 p-2 text-grey-40 " >30.52</td>
                                            <td className="border-b border-b-grey-15 p-2 text-grey-40 " >30.52</td>
                                            <td className="border-b border-b-grey-15 p-2 text-grey-40 " >30.52</td>
                                            <td className="border-b border-b-grey-15 p-2 text-grey-40 " >30.52</td>
                                            <td className="border-b border-b-grey-15 p-2 text-grey-40 " >--</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Technicals;
