import React, { useState } from 'react';
import { Input } from 'antd';
import { KeyOutlined, UserOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { setAuthentication } from '../../components/Auth/auth';
import "./Auth.css"
import { ErrorMessage, SuccessMessage } from '../../components/Messages/messages';
import { Loading } from '../../components/Loading/Loading';

const Login = () => {
  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({
    email: '',
    password: '',

  });

  const { email, password } = userData;

  const handleChange = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value
    });
  }

  const onFinish = async () => {
    window.scrollTo(0, 0);
    setLoading(true);
    await axios.post(`https://bazrbuzz.herokuapp.com/api/users/login`, { email, password }).then(res => {
      setLoading(false);
      if (res.statusText === "OK") {
        console.log(res.data);
        setAuthentication(res.data, res.data.token);
        SuccessMessage(res.data.successMessage);
        history('/');
        window.location.reload();
      }
      else {
        ErrorMessage(res.data.errorMessage);
      }
    })
  };

  return (
    <div className='auth-form'>
      <div className='auth-form-inner text-center'>
        <div className='mb-3 logo'>
          <h1>BAZRBUZZ</h1>
        </div>
        {
          loading
            ?
            <div>
              <Loading />
            </div>
            :
            <form onSubmit={onFinish}>
              <div className="floating-label-group mb-3">
                <Input required name='email' onChange={handleChange} size='small' placeholder="Email or Username" prefix={<UserOutlined />} />
              </div>
              <div className="floating-label-group">
                <Input.Password required name='password' type='password' onChange={handleChange} size="small" placeholder="Password" prefix={<KeyOutlined />} />
              </div>
              <button type='submit' className='btn my-2 mt-4 w-100'>
                Login
              </button>
            </form>
        }
        <div className='mt-2'>
          <p>
            New Here? <Link to='/signup'>Register</Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Login;