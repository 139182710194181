/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/style-prop-object */
import ReactECharts from 'echarts-for-react';
import { useState } from 'react';

function FundamentalsOverview({ selectedCSV2Stock, selectedStock }) {
  const [varying, setVarying] = useState("annual");
  console.log(selectedCSV2Stock?.income_statement_0_gross_profit_annual / 1000000000)

  return (
    <div className="">
      {/* <div className="text-grey-20  p-4  bg-grey-10">
        <h4 className="text-sm mb-3 font-bold text-white">Apple Inc financial statements, including revenue, expenses, profit, and loss</h4>
        <p className="text-sm">The current AAPL market cap is 2.809T USD. The company's EPS TTM is 6.08 USD, dividend yield is 0.51%, and P/E is 28.53. Next Apple Inc earnings date is April 28, the estimation is 1.43 USD.</p>
      </div> */}
      <div className="m-3">
        <button className=" bg-grey-5 rounded-md px-2 py-0.5 text-sm mr-5 text-grey-20" onClick={() => setVarying("annual")}>Annual</button>
        {/* <button className=" text-grey-20 text-sm dark:hover:text-white" onClick={() => setVarying("quarterly")}> Quarterly</button> */}
      </div>
      <div className="grid md:grid-cols-2 p-3 gap-2">
        <div className="w-full h-auto ">
          <ReactECharts
            option={{
              height: '180px',
              title: {
                text: 'Income Statement',
                textStyle: {
                  color: '#d1d4dc',
                  fontSize: '16px',
                }
              },
              tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'cross',
                  crossStyle: {
                    color: '#999'
                  }
                }
              },
              toolbox: {
                feature: {
                  dataView: { show: false, readOnly: false },
                  magicType: { show: false, type: ['line', 'bar'] },
                  restore: { show: false },
                  saveAsImage: { show: false }
                }
              },
              legend: {
                data: ['Revenue', 'Net Income', 'Profit Margin QoQ'],
                top: '88%',
                textStyle: {
                  color: '#fff',
                  fontSize: '12px',
                }
              },
              xAxis: [
                {
                  type: 'category',
                  data: varying === "annual" ? [
                    selectedCSV2Stock?.income_statement_3_fiscal_date_annual?.slice(0, 4),
                    selectedCSV2Stock?.income_statement_2_fiscal_date_annual?.slice(0, 4),
                    selectedCSV2Stock?.income_statement_1_fiscal_date_annual?.slice(0, 4),
                    selectedCSV2Stock?.income_statement_0_fiscal_date_annual?.slice(0, 4)
                  ]
                    : [
                      selectedCSV2Stock?.income_statement_3_fiscal_date_quarterly?.slice(2, 4),
                      selectedCSV2Stock?.income_statement_2_fiscal_date_quarterly?.slice(2, 4),
                      selectedCSV2Stock?.income_statement_1_fiscal_date_quarterly?.slice(2, 4),
                      selectedCSV2Stock?.income_statement_0_fiscal_date_quarterly?.slice(2, 4)
                    ],
                  axisPointer: {
                    type: 'shadow'
                  }
                }
              ],
              yAxis: [
                {
                  type: 'value',
                  position: 'right',
                  nameGap: 2,
                  min: 0,
                  max: (selectedCSV2Stock?.income_statement_0_sales_annual / 1000000000 + 30).toFixed(0),
                  interval:
                    selectedCSV2Stock?.income_statement_0_gross_profit_annual / 1000000000 > 500 ?
                      250
                      :
                      150,
                  axisLabel: {
                    formatter: '{value} B'
                  }
                }
              ],
              series: [
                {
                  name: 'Revenue',
                  type: 'bar',
                  tooltip: {
                    valueFormatter: function (value) {
                      return value + ' B';
                    }
                  },
                  data: varying === "annual" ? [
                    (selectedCSV2Stock?.income_statement_3_sales_annual / 1000000000).toFixed(2),
                    (selectedCSV2Stock?.income_statement_2_sales_annual / 1000000000).toFixed(2),
                    (selectedCSV2Stock?.income_statement_1_sales_annual / 1000000000).toFixed(2),
                    (selectedCSV2Stock?.income_statement_0_sales_annual / 1000000000).toFixed(2)
                  ]
                    : [
                      (selectedCSV2Stock?.income_statement_3_sales_quarterly / 1000000000).toFixed(2),
                      (selectedCSV2Stock?.income_statement_2_sales_quarterly / 1000000000).toFixed(2),
                      (selectedCSV2Stock?.income_statement_1_sales_quarterly / 1000000000).toFixed(2),
                      (selectedCSV2Stock?.income_statement_0_sales_quarterly / 1000000000).toFixed(2)
                    ]
                },
                {
                  name: 'Net Income',
                  type: 'bar',
                  tooltip: {
                    valueFormatter: function (value) {
                      return value + ' B';
                    }
                  },
                  data: varying === "annual" ? [
                    (selectedCSV2Stock?.income_statement_3_net_income_annual / 1000000000).toFixed(2),
                    (selectedCSV2Stock?.income_statement_2_net_income_annual / 1000000000).toFixed(2),
                    (selectedCSV2Stock?.income_statement_1_net_income_annual / 1000000000).toFixed(2),
                    (selectedCSV2Stock?.income_statement_0_net_income_annual / 1000000000).toFixed(2)
                  ]
                    : [
                      (selectedCSV2Stock?.income_statement_3_net_income_quarterly / 1000000000).toFixed(2),
                      (selectedCSV2Stock?.income_statement_2_net_income_quarterly / 1000000000).toFixed(2),
                      (selectedCSV2Stock?.income_statement_1_net_income_quarterly / 1000000000).toFixed(2),
                      (selectedCSV2Stock?.income_statement_0_net_income_quarterly / 1000000000).toFixed(2)
                    ]
                },
                {
                  name: 'Profit Margin YoY',
                  type: 'line',
                  tooltip: {
                    valueFormatter: function (value) {
                      return value + ' B';
                    }
                  },
                  data: varying === "annual" ? [
                    Math.round(selectedCSV2Stock?.income_statement_3_net_income_annual / selectedCSV2Stock?.income_statement_3_sales_annual * 100) / 100,
                    Math.round(selectedCSV2Stock?.income_statement_2_net_income_annual / selectedCSV2Stock?.income_statement_2_sales_annual * 100) / 100,
                    Math.round(selectedCSV2Stock?.income_statement_1_net_income_annual / selectedCSV2Stock?.income_statement_1_sales_annual * 100) / 100,
                    Math.round(selectedCSV2Stock?.income_statement_0_net_income_annual / selectedCSV2Stock?.income_statement_0_sales_annual * 100) / 100,
                  ]
                    : [
                      Math.round(selectedCSV2Stock?.income_statement_3_net_income_quarterly / selectedCSV2Stock?.income_statement_3_sales_quarterly * 100) / 100,
                      Math.round(selectedCSV2Stock?.income_statement_2_net_income_quarterly / selectedCSV2Stock?.income_statement_2_sales_quarterly * 100) / 100,
                      Math.round(selectedCSV2Stock?.income_statement_1_net_income_quarterly / selectedCSV2Stock?.income_statement_1_sales_quarterly * 100) / 100,
                      Math.round(selectedCSV2Stock?.income_statement_0_net_income_quarterly / selectedCSV2Stock?.income_statement_0_sales_quarterly * 100) / 100,
                    ]
                }
              ]
            }} />
        </div>
        {/* <div className="grid md:grid-cols-2 p-3 gap-2"> */}
        <div className="w-full h-auto ">
          <ReactECharts
            option={{
              height: '180px',

              title: {
                text: 'Balance Sheet',
                textStyle: {
                  color: '#fff',
                  fontSize: '16px',
                }
              },
              tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'cross',
                  crossStyle: {
                    color: '#fff'
                  }
                }
              },
              toolbox: {
                feature: {
                  dataView: { show: false, readOnly: false },
                  magicType: { show: false, type: ['line', 'bar'] },
                  restore: { show: false },
                  saveAsImage: { show: false }
                }
              },
              legend: {
                data: ['Total Assets', 'Total Liabilities', 'Profit Margin YoY'],
                top: '88%',
                textStyle: {
                  color: '#fff',
                  fontSize: '12px',
                }
              },
              xAxis: [
                {
                  type: 'category',
                  data: [
                    selectedCSV2Stock?.balance_sheet_3_fiscal_date_annual?.slice(0, 4),
                    selectedCSV2Stock?.balance_sheet_2_fiscal_date_annual?.slice(0, 4),
                    selectedCSV2Stock?.balance_sheet_1_fiscal_date_annual?.slice(0, 4),
                    selectedCSV2Stock?.balance_sheet_0_fiscal_date_annual?.slice(0, 4)
                  ],
                  axisPointer: {
                    type: 'shadow'
                  }
                }
              ],
              yAxis: [
                {
                  type: 'value',
                  position: 'right',
                  nameGap: 2,
                  min: 0,
                  max: (selectedCSV2Stock?.balance_sheet_0_assets_total_assets_annual / 1000000000).toFixed(0),
                  interval: selectedCSV2Stock?.balance_sheet_0_assets_total_assets_annual / 1000000000 > 500 ? 250 : 50,
                  axisLabel: {
                    formatter: '{value} B'
                  }
                }
              ],
              series: [
                {
                  name: 'Total Assets',
                  type: 'bar',
                  tooltip: {
                    valueFormatter: function (value) {
                      return value + ' B';
                    }
                  },
                  data: [
                    (selectedCSV2Stock?.balance_sheet_3_assets_total_assets_annual / 1000000000).toFixed(2),
                    (selectedCSV2Stock?.balance_sheet_2_assets_total_assets_annual / 1000000000).toFixed(2),
                    (selectedCSV2Stock?.balance_sheet_1_assets_total_assets_annual / 1000000000).toFixed(2),
                    (selectedCSV2Stock?.balance_sheet_0_assets_total_assets_annual / 1000000000).toFixed(2)
                  ]
                },
                {
                  name: 'Total Liabilities',
                  type: 'bar',
                  tooltip: {
                    valueFormatter: function (value) {
                      return value + ' B';
                    }
                  },
                  data: [
                    (selectedCSV2Stock?.balance_sheet_3_liabilities_total_liabilities_annual / 1000000000).toFixed(2),
                    (selectedCSV2Stock?.balance_sheet_2_liabilities_total_liabilities_annual / 1000000000).toFixed(2),
                    (selectedCSV2Stock?.balance_sheet_1_liabilities_total_liabilities_annual / 1000000000).toFixed(2),
                    (selectedCSV2Stock?.balance_sheet_0_liabilities_total_liabilities_annual / 1000000000).toFixed(2)
                  ]
                },
                {
                  name: 'Profit Margin YoY',
                  type: 'line',
                  tooltip: {
                    valueFormatter: function (value) {
                      return value == 'Infinity' ? "NA" : value + ' B';
                    }
                  },
                  data: [
                    Math.round(selectedCSV2Stock?.balance_sheet_3_liabilities_total_liabilities_annual / selectedCSV2Stock?.balance_sheet_3_assets_total_assets_annual * 100) / 100,
                    Math.round(selectedCSV2Stock?.balance_sheet_2_liabilities_total_liabilities_annual / selectedCSV2Stock?.balance_sheet_2_assets_total_assets_annual * 100) / 100,
                    Math.round(selectedCSV2Stock?.balance_sheet_1_liabilities_total_liabilities_annual / selectedCSV2Stock?.balance_sheet_1_assets_total_assets_annual * 100) / 100,
                    Math.round(selectedCSV2Stock?.balance_sheet_0_liabilities_total_liabilities_annual / selectedCSV2Stock?.balance_sheet_0_assets_total_assets_annual * 100) / 100,
                  ]
                }
              ]
            }} />
        </div>
        {/* </div> */}
        {/* <div className="w-full h-auto ">
          <ReactECharts
            option={{
              height: '200px',
              title: {
                text: 'Cash flow',
                textStyle: {
                  color: '#d1d4dc',
                  fontSize: '16px',
                }
              },
              tooltip: {
                trigger: 'axis'
              },
              legend: {
                data: ['Actual', 'Estimate'],
                top: '88%',
                textStyle: {
                  color: '#fff',
                  fontSize: '12px',
                },
                padding: 6
              },
              grid: {
                left: '4%',
                right: '4%',
                bottom: '3%',
                containLabel: true,
              },
              toolbox: {
                feature: {
                  saveAsImage: { show: false }
                }
              },
              xAxis: {
                type: 'category',
                boundaryGap: true,
                data: ['2017', '2018', '2019', '2020', '2021']
              },
              yAxis: {
                type: 'value',
                position: 'right',
                nameGap: 1,
              },
              series: [
                {
                  name: 'Actual',
                  type: 'line',
                  data: [0.95, 1.25, 1.55, 1.89, 1.95]
                },
                {
                  name: 'Estimate',
                  type: 'line',
                  data: [0.77, 1.10, 1.25, 1.70, 1.8]
                },
              ]
            }} />
        </div> */}
      </div>
    </div>
  );
}

export default FundamentalsOverview;
