import phone from '../../assets/phone.png';
import email from '../../assets/email.png';
import { Link } from "react-router-dom";
import { useEffect, useState } from 'react';
import axios from 'axios';
import { ErrorMessage } from '../Messages/messages';

function Footer() {
  const [data, setData] = useState([]);

  const getData = async () => {
    await axios.get(`https://bazrbuzz.herokuapp.com/api/footer`).then(res => {
      if (res.statusText === "OK") {
        setData(res.data);
      } else {
        ErrorMessage(res.data.errorMessage);
      }
    })
  }

  useEffect(() => {
    getData();
    return () => {
    }
  }, []);

  return (
    <footer style={{ position: "relative", textAlign: "center" }}>
      <div className="p-5 bg-black-700 text-left">
        <div className="md:container md:mx-auto sm:m-5 mt-5  ">
          <div className=" grid md:grid-cols-3 mt-4 gap-6">
            <section>
              <h2 className="text-lg font-semibold text-gray-800 text-left dark:text-white ">
                <span className="text-white ">BAZR</span>
                <span className="text-green-800">BUZZ</span>
              </h2>
              <p className="text-grey-40 text-md mt-6">
                We provide training and a cutting-edge investing dashboard for retail investors utilizing Artificial Intelligence (AI), big data, machine learning, and natural language processing.
              </p>
            </section>
            <section className="sm:flex sm:justify-center">
              <div>
                <h1 className="text-white text-md font-bold mb-5">Get in touch</h1>
                <div className="flex items-center">
                  <img src={email} alt="email"></img>
                  <div className="leading-5 ml-4 text-grey-40 text-sm">
                    <p className='mb-0'>Email</p>
                    <a href={`mailto: ${data.filter(d => d?.type === "email")[0]?.text}`} className='mb-0'>{data.filter(d => d?.type === "email")[0]?.text}</a>
                    {/* <p>BazrBuzz@gmail.com</p> */}
                  </div>
                </div>
                {/* <div className="flex items-center mt-10">
                  <img src={phone} alt="Phone"></img>
                  <div className="leading-5 ml-4 text-grey-40 text-sm">
                    <p className='mb-0'>Phone</p>
                    <p className='mb-0'>{data.filter(d => d?.type === "phone")[0]?.text}</p>
                  </div>
                </div> */}
              </div>
            </section>
            <section className="text-grey-40 sm:flex sm:justify-center">
              <div>
                <h1 className="text-white text-md font-bold mb-5">Quick Links</h1>
                {
                  data && data.length > 0 && data.filter(d => d?.type === "link").map(d => {
                    return (
                      <>
                        <Link to={d.link}>{d.text}</Link>
                        <br />
                      </>
                    )
                  })
                }
              </div>
            </section>
          </div>
        </div>
      </div>
      <div className="  bg-green-500">
        <div className="md:container md:mx-auto  p-3">
          <p className="text-center font-bold text-sm">© 2023 BazrBuzz - All Rights Reserved</p>
        </div>
      </div>
    </footer>
  );

}

export default Footer;