import React from 'react'
import loading from "../../assets/loading.gif";
import "./Loading.css";

export const Loading = () => {
    return (
        <div className='loading-icon' style={{ height: "10vh" }}>
            <img src={loading} alt="Loading" width="43" />
        </div>
    )
}
