import React from 'react'
import ReactECharts from 'echarts-for-react';

const BuySellChart = ({ filterData, value1, value2, title, buySell }) => {
    // let buyPercentage = (filterData.filter(d => d.summary_signal.includes("Buy")).length * 100 / filterData.length);
    // let sellPercentage = (filterData.filter(d => d.summary_signal.includes("Sell")).length * 100 / filterData.length);

    return (
        <div className="rounded-lg h-auto pb-0 overflow-auto grid grid-cols-1 dark:bg-black-700 pl-0 p-0 text-white">
            <h2 className="text-sm text-white p-4 pb-0">{title}</h2>
            <div className="grid grid-cols-1 content-center m-auto p-0">
                <ReactECharts
                    style={{ padding: '0px', height: '180px', width: '180px', top: '0px', bottom: '0px', left: 0 }}
                    option={{
                        width: '180px',
                        height: '180px',
                        padding: 0,
                        itemGap: 0,
                        responsive: false,
                        maintainAspectRatio: false,
                        series: [
                            {
                                name: 'Access From',
                                type: 'pie',
                                radius: ['60%', '50%'],
                                avoidLabelOverlap: false,
                                color: ['#FF4D35', '#34FEC2'],
                                label: {
                                    show: false,
                                    position: 'center'
                                },
                                emphasis: {

                                    scaleSize: 0
                                },
                                labelLine: {
                                    show: false
                                },

                                data: [
                                    { value: value1, name: 'Search Engine' },
                                    { value: value2, name: 'Direct' }
                                ],
                                //     { value: filterData.filter(d => d.percent_change < 0).length, name: 'Search Engine' },
                                //     { value: filterData.filter(d => d.percent_change > 0).length, name: 'Direct' }
                                // ],
                                // data: [
                                //     { value: filterData.filter(d => d.summary_signal.includes("Sell")).length, name: 'Search Engine' },
                                //     { value: filterData.filter(d => d.summary_signal.includes("Buy")).length, name: 'Direct' }
                                // ],
                            }
                        ]
                    }} />
            </div>
            <div className="flex justify-center align-middle">
                <div>
                    <div className="text-center flex items-center align-middle p-2 gap-4 text-sm">
                        <span>{buySell ? "Buy" : "Up"}</span>
                        <div>
                            <span className=" bg-red rounded-md h-4 w-7 block bg-blue-500"></span>
                        </div>
                        <span className="text-blue-500">
                            {value2}
                        </span>
                    </div>
                    <div className="text-left flex items-center align-middle gap-4 p-2 text-sm">
                        {/* <span>Sell</span> */}
                        <span>{buySell ? "Sell" : "Down"}</span>
                        <span className=" bg-red rounded-md h-4 w-7 block bg-red-500">
                        </span>
                        <span className="text-red-500">
                            {value1}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BuySellChart
