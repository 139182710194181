import React from 'react'
import ReactECharts from 'echarts-for-react';

const AreaChart = () => {
    let filterData = JSON.parse(localStorage.getItem("AreaChart"));
    // console.log(filterData?.time_series_10_close)
    return (
        <div className="areaChart rounded-lg h-auto dark:bg-black-700 text-white">
            <h2 className="text-sm text-white p-4 pb-0">Nifty Chart (1 Month)</h2>
            <div className="content-center inner">
                <ReactECharts
                    className="chartMain areaChartInner"
                    option={
                        {
                            xAxis: {
                                show: false,
                                type: 'category',
                                boundaryGap: false,
                                data: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24']
                            },
                            yAxis: {
                                scale: true,
                                show: false
                                // splitArea: {
                                //     show: true
                                // }
                            },
                            series: [
                                {
                                    data: [
                                        filterData?.time_series_1_close,
                                        filterData?.time_series_2_close,
                                        filterData?.time_series_3_close,
                                        filterData?.time_series_4_close,
                                        filterData?.time_series_5_close,
                                        filterData?.time_series_6_close,
                                        filterData?.time_series_7_close,
                                        filterData?.time_series_8_close,
                                        filterData?.time_series_9_close,
                                        filterData?.time_series_10_close,
                                        filterData?.time_series_11_close,
                                        filterData?.time_series_12_close,
                                        filterData?.time_series_13_close,
                                        filterData?.time_series_14_close,
                                        filterData?.time_series_15_close,
                                        filterData?.time_series_16_close,
                                        filterData?.time_series_17_close,
                                        filterData?.time_series_18_close,
                                        filterData?.time_series_19_close,
                                        filterData?.time_series_20_close,
                                        filterData?.time_series_21_close,
                                        filterData?.time_series_22_close,
                                        filterData?.time_series_23_close,
                                        filterData?.time_series_24_close,
                                    ],
                                    type: 'line',
                                    areaStyle: {}
                                }
                            ]
                        }
                    }
                />
            </div>
        </div>
    )
}

export default AreaChart;
