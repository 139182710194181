/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/style-prop-object */
import React from 'react';
import { FormatNumbers } from '../FormatNumber';
import Gauge from '../Gauge';
import GrasimChart from '../GrasimChart';

function Overview({ selectedStock, selectedCSV2Stock }) {
    let signal = selectedStock?.summary_signal
    let arrowPointerValue = signal === "Strong Buy" ? 0.875 : signal === "Buy" ? 0.75 : signal === "Neutral" ? 0.5 : signal === "Sell" ? 0.25 : signal === "Strong Sell" && 0.125

    return (
        <div className="grid md:grid-cols-1 mt-5 Latest">
            <div className="md:col-span-2 ">
                {/* <div className="sm:flex grid grid-cols-2 gap-0">
                    <div className="sm:flex-shrink-0 text-xs text-green-800 w-fit text-left h-auto bg-green-10 transparent rounded-md px-3 py-2 m-1"><span>Short Term Buy Signal </span></div>
                    <div className="sm:flex-shrink-0 text-xs text-green-800 w-fit text-left h-auto bg-green-10 transparent rounded-md px-3 py-2 m-1">Outerforms Market</div>
                    <div className="sm:flex-shrink-0 text-xs text-green-800 w-fit text-left h-auto bg-green-10 transparent rounded-md px-3 py-2 m-1">Moving Average Crossover</div>
                    <div className="sm:flex-shrink-0 text-xs text-green-800 w-fit  text-left h-auto bg-green-10 transparent rounded-md px-3 py-2 m-1">Near 52 High</div>
                </div> */}
                <p className="text-white mt-2 text-[16px] font-[800] p-3 mb-0 pb-0 text-md">{selectedStock?.title}</p>
                <p className="text-white p-3 pt-2 text-md">{selectedStock?.content_top}</p>
                <p className="text-white mt-2 text-[16px] font-[800] p-3 pb-0 mb-0 text-md">{selectedStock?.subtitle1}</p>
                <p className="text-white p-3 pt-2 text-md">{selectedStock?.content1}</p>
                <p className="text-white mt-2 text-[16px] font-[800] p-3 pb-0 mb-0 text-md">{selectedStock?.subtitle2}</p>
                <p className="text-white p-3 pt-2 text-md">{selectedStock?.content2}</p>
                <p className="text-white p-3 pt-0 text-md">{selectedStock?.content3}</p>
                <div className="grid grid-cols-1 p-3">
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                        <div className="">
                            <h2 className="text-white mb-1">Stat</h2>
                            <div className="grid grid-cols-2 rounded-lg dark:bg-black-800 pt-4 pb-4 gap-4">
                                <div className="border-r border-black-700 ">
                                    <table class="w-full text-sm ">
                                        <tbody className="p-2">
                                            <tr className="">
                                                <td className="p-2 text-grey-40">Open</td>
                                                <td className="p-2 text-white " >{FormatNumbers(selectedStock?.open)}</td>
                                            </tr>
                                            <tr className="">
                                                <td className="p-2 text-grey-40">High</td>
                                                <td className="p-2 text-white">{FormatNumbers(selectedStock?.high)}</td>
                                            </tr>
                                            <tr className="">
                                                <td className="p-2 text-grey-40">52 Weeks High</td>
                                                <td className="p-2 text-white">{FormatNumbers(selectedStock?.fifty_two_week_high)}</td>
                                            </tr>
                                            {/* <tr>
                                                <td className="p-2 text-grey-40">Low</td>
                                                <td className="p-2 text-white">{selectedStock?.low}</td>
                                            </tr> */}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="">
                                    <table class="w-full text-sm ">
                                        <tbody className="p-2">
                                            <tr className="">
                                                <td className="p-2 text-grey-40">Close</td>
                                                <td className="p-2 text-white " >{FormatNumbers(selectedStock?.close)}</td>
                                            </tr>
                                            <tr className="">
                                                <td className="p-2 text-grey-40">Low</td>
                                                <td className="p-2 text-white ">{FormatNumbers(selectedStock?.low)}</td>
                                            </tr>
                                            <tr className="">
                                                <td className="p-2 text-grey-40">52 weeks Low</td>
                                                <td className="p-2 text-white ">{FormatNumbers(selectedStock?.fifty_two_week_low)}</td>
                                            </tr>
                                            {/* <tr>
                                                <td className="p-2 text-grey-40">Low</td>
                                                <td className="p-2 text-white">{selectedStock?.low}</td>
                                            </tr> */}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="overflow-hidden">
                            <h2 className="text-white ">Signal</h2>
                            <div id="main" className="mb-12 sm:mb-0" style={{ width: '350px', height: '180px', border: '', padding: '0px', left: 0, bottom: 0 }}>
                                <Gauge arrowPointerValue={arrowPointerValue} signal={signal} />
                            </div>
                        </div>
                    </div>
                    <div className="mt-4">
                        <h1 className="text-white mb-4">{selectedStock?.name}</h1>
                        <div className="">
                            <div id="main" className="" style={{ width: '100%', height: '350px', border: '', padding: '0px', left: 0, bottom: 0 }}>
                                <GrasimChart selectedStock={selectedStock} />
                            </div>
                        </div>
                    </div>
                    <div className="lowerTables">
                        <div className="grid md:grid-cols-2 gap-4 rounded-lg dark:bg-black-800 p-2">
                            <div className="">
                                <div className='my-4'>
                                    <h1 className="text-white">Financials</h1>
                                    <h3 className="text-white">Valuation</h3>
                                    {
                                        selectedCSV2Stock?.Stock === "Yes" &&
                                        <table class="w-full  text-sm ">
                                            <tbody className="p-2">
                                                <tr className="">
                                                    <td className="p-2 text-grey-40">Market Capitalization (Billion)</td>
                                                    <td className="p-2 text-white " >{FormatNumbers((selectedCSV2Stock?.statistics_valuations_metrics_market_capitalization / 1000000000).toFixed(2))}B</td>
                                                </tr>
                                                <tr className="">
                                                    <td className="p-2 text-grey-40">Enterprise Value (Billion)</td>
                                                    <td className="p-2 text-white">{(FormatNumbers((selectedCSV2Stock?.statistics_valuations_metrics_enterprise_value / 1000000000).toFixed(2)))}B</td>
                                                </tr>
                                                <tr className="">
                                                    <td className="p-2 text-grey-40">Price Earning Ratio</td>
                                                    <td className="p-2 text-white">{FormatNumbers(parseInt(selectedCSV2Stock?.statistics_valuations_metrics_trailing_pe).toFixed(2))}</td>
                                                </tr>
                                                <tr className="">
                                                    <td className="p-2 text-grey-40">Profit Margin YoY</td>
                                                    <td className="p-2 text-white">{(selectedCSV2Stock?.statistics_financials_profit_margin * 100).toFixed(2)}%</td>
                                                </tr>
                                                <tr className="">
                                                    <td className="p-2 text-grey-40">Operating Margin</td>
                                                    <td className="p-2 text-white">{(selectedCSV2Stock?.statistics_financials_operating_margin * 100).toFixed(2)}%</td>
                                                </tr>
                                                <tr className="">
                                                    <td className="p-2 text-grey-40">Gross Profit</td>
                                                    <td className="p-2 text-white">{FormatNumbers((selectedCSV2Stock?.statistics_financials_income_statement_gross_profit_ttm / 1000000000).toFixed(2))}B</td>
                                                </tr>
                                                <tr className="">
                                                    <td className="p-2 text-grey-40">Net Income</td>
                                                    <td className="p-2 text-white">{FormatNumbers((selectedCSV2Stock?.statistics_financials_income_statement_net_income_to_common_ttm / 1000000000).toFixed(2))}B</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    }
                                </div>
                                <div className='my-4'>
                                    <h3 className="text-white">Insider Transaction</h3>
                                    {
                                        selectedCSV2Stock?.Stock === "Yes" &&
                                        <table class="w-full  text-sm ">
                                            <tbody className="p-2">
                                                <tr className="">
                                                    <td className="p-2 text-grey-40">Name</td>
                                                    <td className="p-2 text-white " >{selectedCSV2Stock?.insider_transactions_0_full_name}</td>
                                                </tr>
                                                <tr className="">
                                                    <td className="p-2 text-grey-40">Position</td>
                                                    <td className="p-2 text-white">{selectedCSV2Stock?.insider_transactions_0_position}</td>
                                                </tr>
                                                <tr className="">
                                                    <td className="p-2 text-grey-40">Date Reported</td>
                                                    <td className="p-2 text-white">{selectedCSV2Stock?.insider_transactions_0_date_reported}</td>
                                                </tr>
                                                <tr className="">
                                                    <td className="p-2 text-grey-40">Number of Shares</td>
                                                    <td className="p-2 text-white">{FormatNumbers(selectedCSV2Stock?.insider_transactions_0_shares)}</td>
                                                </tr>
                                                <tr className="">
                                                    <td className="p-2 text-grey-40">Value</td>
                                                    <td className="p-2 text-white">{(selectedCSV2Stock?.insider_transactions_0_value / 1000000).toFixed(2)}M</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    }
                                </div>
                                <div className='my-4'>
                                    <h3 className="text-white">Important Statistics</h3>
                                    {
                                        selectedCSV2Stock?.Stock === "Yes" &&
                                        <table class="w-full  text-sm ">
                                            <tbody className="p-2">
                                                <tr className="">
                                                    <td className="p-2 text-grey-40">Shares Outstanding</td>
                                                    <td className="p-2 text-white " >{(selectedCSV2Stock?.statistics_stock_statistics_shares_outstanding / 1000000000).toFixed(2)}B</td>
                                                </tr>
                                                <tr className="">
                                                    <td className="p-2 text-grey-40">Stock with Insiders</td>
                                                    <td className="p-2 text-white">{(selectedCSV2Stock?.statistics_stock_statistics_percent_held_by_insiders * 100).toFixed(2)}%</td>
                                                </tr>
                                                <tr className="">
                                                    <td className="p-2 text-grey-40">Stock with Institutions</td>
                                                    <td className="p-2 text-white">{(selectedCSV2Stock?.statistics_stock_statistics_percent_held_by_institutions * 100).toFixed(2)}%</td>
                                                </tr>
                                                <tr className="">
                                                    <td className="p-2 text-grey-40">Beta</td>
                                                    <td className="p-2 text-white">{Math.round(selectedCSV2Stock?.statistics_stock_price_summary_beta * 10000) / 10000}</td>
                                                </tr>
                                                <tr className="">
                                                    <td className="p-2 text-grey-40">Fwd Dividend Yield</td>
                                                    <td className="p-2 text-white">{Math.round(selectedCSV2Stock?.statistics_dividends_and_splits_forward_annual_dividend_yield * 10000) / 10000}%</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    }
                                </div>
                                <div className='my-4'>
                                    <h3 className="text-white">Income Statement</h3>
                                    {
                                        selectedCSV2Stock?.Stock === "Yes" &&
                                        <table class="w-full  text-sm ">
                                            <tbody className="p-2">
                                                <tr className="">
                                                    <td className="p-2 text-grey-40">Revenue</td>
                                                    <td className="p-2 text-white " >{FormatNumbers((selectedCSV2Stock?.statistics_financials_income_statement_revenue_ttm / 1000000000).toFixed(2))}B</td>
                                                </tr>
                                                <tr className="">
                                                    <td className="p-2 text-grey-40">Quarterly Revenue Growth</td>
                                                    <td className="p-2 text-white">{(selectedCSV2Stock?.statistics_financials_income_statement_quarterly_revenue_growth * 100).toFixed(2)}%</td>
                                                </tr>
                                                <tr className="">
                                                    <td className="p-2 text-grey-40">Diluted EPS</td>
                                                    <td className="p-2 text-white">{Math.round(selectedCSV2Stock?.statistics_financials_income_statement_diluted_eps_ttm * 100) / 100}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    }
                                </div>
                                <div className='my-4'>
                                    <h3 className="text-white">Balance Sheet</h3>
                                    {
                                        selectedCSV2Stock?.Stock === "Yes" &&
                                        <table class="w-full  text-sm ">
                                            <tbody className="p-2">
                                                <tr className="">
                                                    <td className="p-2 text-grey-40">Cash Per Share (MRQ)</td>
                                                    <td className="p-2 text-white " >{Math.round(selectedCSV2Stock?.statistics_financials_balance_sheet_total_cash_per_share_mrq * 100) / 100}</td>
                                                </tr>
                                                <tr className="">
                                                    <td className="p-2 text-grey-40">Total Debt (MRQ)</td>
                                                    <td className="p-2 text-white">{FormatNumbers((selectedCSV2Stock?.statistics_financials_balance_sheet_total_debt_mrq / 1000000000).toFixed(2))}B</td>
                                                </tr>
                                                <tr className="">
                                                    <td className="p-2 text-grey-40">Debt to Equity Ratio (MRQ)</td>
                                                    <td className="p-2 text-white">{Math.round(selectedCSV2Stock?.statistics_financials_balance_sheet_total_debt_to_equity_mrq * 100) / 100}</td>
                                                </tr>
                                                <tr className="">
                                                    <td className="p-2 text-grey-40">Book Value (MRQ)</td>
                                                    <td className="p-2 text-white">{Math.round(selectedCSV2Stock?.statistics_financials_balance_sheet_book_value_per_share_mrq * 100) / 100}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    }
                                </div>
                            </div>
                            <div className=" p-2 justify-content">
                                <h1 className="text-white mb-2 ">Profile</h1>
                                <p className="text-lg text-grey-40 mb-0">Sector:</p>
                                <p className="text-[15px] text-grey-40">{selectedCSV2Stock?.sector}</p>
                                <p className="text-lg mb-0 text-grey-40">Industry:</p>
                                <p className="text-[15px] text-grey-40">{selectedCSV2Stock?.description}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Overview;
