/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/style-prop-object */
import React, { useState } from 'react';
import ReactECharts from 'echarts-for-react';

function IncomeStatement({ selectedCSV2Stock }) {
  const [varying, setVarying] = useState("annual");

  return (
    <div className="overflow-hidden">
      {/* <div className="text-grey-20  p-4  bg-grey-10">
        <h4 className="text-sm mb-3 text-white font-bold">Apple Inc financial statements, including revenue, expenses, profit, and loss</h4>
        <p className="text-sm">Financial summary of Apple Inc with all the key numbers
          The current AAPL market cap is 2.804T USD. The company's EPS TTM is 6.08 USD, dividend yield is 0.51%, and P/E is 29.06. Next Apple Inc earnings date is April 28, the estimation is 1.43 USD.</p>
      </div> */}
      <div className="m-3">
        <button className=" bg-grey-5 rounded-md px-2 py-0.5 text-sm mr-5 text-grey-20" onClick={() => setVarying("annual")}>Annual</button>
        <button className=" text-grey-20 text-sm dark:hover:text-white" onClick={() => setVarying("quarterly")}> Quarterly</button>
      </div>
      <div className="grid md:grid-cols-1 ">
        <div className="w-full h-auto p-1 ">
          <ReactECharts
            option={{
              height: '180px',
              title: {
                text: 'Income Statement',
                textStyle: {
                  color: '#d1d4dc',
                  fontSize: '16px',
                }
              },
              tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'cross',
                  crossStyle: {
                    color: '#999'
                  }
                }
              },
              toolbox: {
                feature: {
                  dataView: { show: false, readOnly: false },
                  magicType: { show: false, type: ['line', 'bar'] },
                  restore: { show: false },
                  saveAsImage: { show: false }
                }
              },
              legend: {
                data: ['Revenue', 'Net Income', 'Profit Margin QoQ'],
                top: '88%',
                textStyle: {
                  color: '#fff',
                  fontSize: '12px',
                }
              },
              xAxis: [
                {
                  type: 'category',
                  data: varying === "annual" ? [
                    selectedCSV2Stock?.income_statement_3_fiscal_date_annual.slice(0, 4),
                    selectedCSV2Stock?.income_statement_2_fiscal_date_annual.slice(0, 4),
                    selectedCSV2Stock?.income_statement_1_fiscal_date_annual.slice(0, 4),
                    selectedCSV2Stock?.income_statement_0_fiscal_date_annual.slice(0, 4)
                  ]
                    : [
                      selectedCSV2Stock?.income_statement_3_fiscal_date_quarterly.slice(2, 4),
                      selectedCSV2Stock?.income_statement_2_fiscal_date_quarterly.slice(2, 4),
                      selectedCSV2Stock?.income_statement_1_fiscal_date_quarterly.slice(2, 4),
                      selectedCSV2Stock?.income_statement_0_fiscal_date_quarterly.slice(2, 4)
                    ],
                  axisPointer: {
                    type: 'shadow'
                  }
                }
              ],
              yAxis: [
                {
                  type: 'value',
                  position: 'right',
                  nameGap: 2,
                  min: 0,
                  max: (selectedCSV2Stock?.balance_sheet_0_assets_total_assets_annual / 1000000000).toFixed(0),
                  interval: selectedCSV2Stock.balance_sheet_0_assets_total_assets_annual / 1000000000 > 500 ? 250 : 50,
                  axisLabel: {
                    formatter: '{value} B'
                  }
                }
              ],
              series: [
                {
                  name: 'Revenue',
                  type: 'bar',
                  tooltip: {
                    valueFormatter: function (value) {
                      return value + ' B';
                    }
                  },
                  data: varying === "annual" ? [
                    (selectedCSV2Stock?.income_statement_3_sales_annual / 1000000000).toFixed(2),
                    (selectedCSV2Stock?.income_statement_2_sales_annual / 1000000000).toFixed(2),
                    (selectedCSV2Stock?.income_statement_1_sales_annual / 1000000000).toFixed(2),
                    (selectedCSV2Stock?.income_statement_0_sales_annual / 1000000000).toFixed(2)
                  ]
                    : [
                      (selectedCSV2Stock?.income_statement_3_sales_quarterly / 1000000000).toFixed(2),
                      (selectedCSV2Stock?.income_statement_2_sales_quarterly / 1000000000).toFixed(2),
                      (selectedCSV2Stock?.income_statement_1_sales_quarterly / 1000000000).toFixed(2),
                      (selectedCSV2Stock?.income_statement_0_sales_quarterly / 1000000000).toFixed(2)
                    ]
                },
                {
                  name: 'Net Income',
                  type: 'bar',
                  tooltip: {
                    valueFormatter: function (value) {
                      return value + ' B';
                    }
                  },
                  data: varying === "annual" ? [
                    (selectedCSV2Stock?.income_statement_3_net_income_annual / 1000000000).toFixed(2),
                    (selectedCSV2Stock?.income_statement_2_net_income_annual / 1000000000).toFixed(2),
                    (selectedCSV2Stock?.income_statement_1_net_income_annual / 1000000000).toFixed(2),
                    (selectedCSV2Stock?.income_statement_0_net_income_annual / 1000000000).toFixed(2)
                  ]
                    : [
                      (selectedCSV2Stock?.income_statement_3_net_income_quarterly / 1000000000).toFixed(2),
                      (selectedCSV2Stock?.income_statement_2_net_income_quarterly / 1000000000).toFixed(2),
                      (selectedCSV2Stock?.income_statement_1_net_income_quarterly / 1000000000).toFixed(2),
                      (selectedCSV2Stock?.income_statement_0_net_income_quarterly / 1000000000).toFixed(2)
                    ]
                },
                {
                  name: 'Profit Margin YoY',
                  type: 'line',
                  tooltip: {
                    valueFormatter: function (value) {
                      return value + ' B';
                    }
                  },
                  data: varying === "annual" ? [
                    selectedCSV2Stock?.income_statement_3_net_income_annual / selectedCSV2Stock?.income_statement_3_sales_annual,
                    selectedCSV2Stock?.income_statement_2_net_income_annual / selectedCSV2Stock?.income_statement_2_sales_annual,
                    selectedCSV2Stock?.income_statement_1_net_income_annual / selectedCSV2Stock?.income_statement_1_sales_annual,
                    selectedCSV2Stock?.income_statement_0_net_income_annual / selectedCSV2Stock?.income_statement_0_sales_annual,
                  ]
                    : [
                      selectedCSV2Stock?.income_statement_3_net_income_quarterly / selectedCSV2Stock?.income_statement_3_sales_quarterly,
                      selectedCSV2Stock?.income_statement_2_net_income_quarterly / selectedCSV2Stock?.income_statement_2_sales_quarterly,
                      selectedCSV2Stock?.income_statement_1_net_income_quarterly / selectedCSV2Stock?.income_statement_1_sales_quarterly,
                      selectedCSV2Stock?.income_statement_0_net_income_quarterly / selectedCSV2Stock?.income_statement_0_sales_quarterly,
                    ]
                }
              ]
            }} />
        </div>

      </div>


      {/*
    <div class=" overflow-x-auto  max-w-sm m-auto	 md:max-w-full  text-sm text-grey-120 ">
            <div class="">
            <table class="  max-w-sm m-auto	 md:max-w-full whitespace-nowrap  overflow-auto" >
                <thead class=" whitespace-nowrap">
                    <tr class="whitespace-nowrap ">
                    <th   class="text-grey-110 font-normal border-t  border-t-grey-15 w-1/4  bg-grey-700 px-2 left-0 z-40  ">Currency: USD</th>
                    <th class="border-t border-t-grey-15 p-2  text-grey-110  font-normal 	  bg-grey-700 -z-0">dddddddd2015</th>
                    <th class="border-t border-t-grey-15 p-2  text-grey-110  font-normal 	 -z-0">dddddddddd2016</th>
                    <th class="border-t border-t-grey-15 p-2  text-grey-110  font-normal 	 -z-0">2ddddddddddd017</th>
                    <th class="border-t border-t-grey-15 p-2  text-grey-110 font-normal  	-z-0">2dddd018</th>
                    <th class="border-t border-t-grey-15 p-2  text-grey-110 font-normal  	 -z-0">2dddddddddd019</th>
                    <th class="border-t border-t-grey-15 p-2  text-grey-110 font-normal  	 -z-0">20ddddd20</th>
                    <th class="border-t border-t-grey-15 p-2 text-grey-110 font-normal  	 -z-0">20ddddddd21</th>
                    </tr>


                </thead>
                <tbody class=" ">
                    <tr class=" ">

                    <th  class="border-t border-t-grey-15  px-2 px-4 bg-grey-700  w-1/2 left-0 z-40 whitespace-nowrap">Total liabilities
</th>
                    <th class="border-t border-t-grey-15 p-2 z-0  whitespace-nowrap  	 ">‪290.48B‬<br></br><span class="text-blue-500">+25.29%</span></th>
                    <th class="border-t border-t-grey-15 p-2 z-0 whitespace-nowrap 	">321.69B‬<br></br><span class="text-blue-500">+25.29%</span></th>
                    <th class="border-t border-t-grey-15 p-2 z-0  whitespace-nowrap 	">375.32B‬<br></br><span class="text-red-500">+25.29%</span></th>
                    <th class="border-t border-t-grey-15 p-2 z-0 whitespace-nowrap 	">365.73B‬<br></br><span class="text-blue-500">+25.29%</span></th>
                    <th class="border-t border-t-grey-15 p-2 z-0 whitespace-nowrap 	">‪338.52B<br></br><span class="text-blue-500">+25.29%</span>‬</th>
                    <th class="border-t border-t-grey-15 p-2 z-0  whitespace-nowrap 	">323.89B‬<br></br><span class="text-red-500">+25.29%</span></th>
                    <th class="border-t border-t-grey-15 p-2 z-0  whitespace-nowrap 	">351.00B‬<br></br><span class="text-blue-500">+25.29%</span></th>

                    </tr>
             




                </tbody>
            
            </table>

            </div>

        </div>

        */}


      {/* <div className="overflow-x-auto max-w-sm m-auto	 md:max-w-full  text-sm text-grey-120  whitespace-nowrap	">
        <div className="">
          <table className=" w-full max-w-sm m-auto	 md:max-w-full  whitespace-nowrap overflow-auto " >
            <thead className=" whitespace-nowrap">
              <tr className="whitespace-nowrap relative z-0 ">
                <th className="text-grey-110 font-normal border-t  border-t-grey-15 w-16   bg-grey-700 px-2 left-0 z-40  ">Currency: USD</th>
                <th className="border-t border-t-grey-15 p-2  text-grey-110  font-normal 	   -z-0">2015</th>
                <th className="border-t border-t-grey-15 p-2  text-grey-110  font-normal   	 -z-0">2016</th>
                <th className="border-t border-t-grey-15 p-2  text-grey-110  font-normal 	 -z-0">2017</th>
                <th className="border-t border-t-grey-15 p-2  text-grey-110 font-normal   	-z-0">2018</th>
                <th className="border-t border-t-grey-15 p-2  text-grey-110 font-normal   	 -z-0">2019</th>
                <th className="border-t border-t-grey-15 p-2  text-grey-110 font-normal  	 -z-0">2020</th>
                <th className="border-t border-t-grey-15 p-2 text-grey-110 font-normal   	 -z-0">2021</th>
              </tr>


            </thead>
            <tbody className=" whitespace-nowrap">
              <tr className="whitespace-nowrap ">

                <th className="border-t border-t-grey-15  px-2 px-4  w-16 left-0 z-9999 whitespace-nowrap">Total liabilities
                </th>
                <th className="border-t border-t-grey-15 p-2 z-0  whitespace-nowrap  	 ">‪290.48B‬<br></br><span className="text-blue-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0 whitespace-nowrap 	">321.69B‬<br></br><span className="text-blue-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0  whitespace-nowrap 	">375.32B‬<br></br><span className="text-red-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0 whitespace-nowrap 	">365.73B‬<br></br><span className="text-blue-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0 whitespace-nowrap 	">‪338.52B<br></br><span className="text-blue-500">+25.29%</span>‬</th>
                <th className="border-t border-t-grey-15 p-2 z-0  whitespace-nowrap 	">323.89B‬<br></br><span className="text-red-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0  whitespace-nowrap 	">351.00B‬<br></br><span className="text-blue-500">+25.29%</span></th>

              </tr>
              <tr >

                <th className="border-t border-t-grey-15  w-16 px-2  bg-grey-700  left-0 z-40 overflow-hidden text-ellipsis">Total equity
                </th>
                <th className="border-t border-t-grey-15 p-2 z-0 ">‪290.48B‬<br></br><span className="text-blue-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0">321.69B‬<br></br><span className="text-blue-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0">375.32B‬<br></br><span className="text-red-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0">365.73B‬<br></br><span className="text-blue-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0">‪338.52B<br></br><span className="text-blue-500">+25.29%</span>‬</th>
                <th className="border-t border-t-grey-15 p-2 z-0 ">323.89B‬<br></br><span className="text-red-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0 ">351.00B‬<br></br><span className="text-blue-500">+25.29%</span></th>

              </tr>
              <tr >

                <th className="border-t border-t-grey-15  w-16 px-2  bg-grey-700  left-0 z-40  	overflow-hidden text-ellipsis" alt="Total liabilities & shareholders' equities"><p className="w-48 overflow-hidden text-ellipsis">Total liabilities & shareholders' equities</p>
                </th>
                <th className="border-t border-t-grey-15 p-2 -z-0 ">‪290.48B‬<br></br><span className="text-blue-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0">321.69B‬<br></br><span className="text-blue-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0">375.32B‬<br></br><span className="text-red-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0">365.73B‬<br></br><span className="text-blue-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0">‪338.52B<br></br><span className="text-blue-500">+25.29%</span>‬</th>
                <th className="border-t border-t-grey-15 p-2 z-0 ">323.89B‬<br></br><span className="text-red-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0 ">351.00B‬<br></br><span className="text-blue-500">+25.29%</span></th>

              </tr>
              <tr >

                <th className="border-t border-t-grey-15  w-16 px-2  bg-grey-700  left-0 z-40 ">Total debt
                </th>
                <th className="border-t border-t-grey-15 p-2 z-0 ">‪290.48B‬<br></br><span className="text-blue-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0">321.69B‬<br></br><span className="text-blue-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0">375.32B‬<br></br><span className="text-red-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0">365.73B‬<br></br><span className="text-blue-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0">‪338.52B<br></br><span className="text-blue-500">+25.29%</span>‬</th>
                <th className="border-t border-t-grey-15 p-2 z-0 ">323.89B‬<br></br><span className="text-red-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0 ">351.00B‬<br></br><span className="text-blue-500">+25.29%</span></th>

              </tr>
              <tr >

                <th className="border-t border-t-grey-15  w-16 px-2  bg-grey-700  left-0 z-40">Net debt
                </th>
                <th className="border-t border-t-grey-15 p-2 z-0 ">‪290.48B‬<br></br><span className="text-blue-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0">321.69B‬<br></br><span className="text-blue-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0">375.32B‬<br></br><span className="text-red-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0">365.73B‬<br></br><span className="text-blue-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0">‪338.52B<br></br><span className="text-blue-500">+25.29%</span>‬</th>
                <th className="border-t border-t-grey-15 p-2 z-0 ">323.89B‬<br></br><span className="text-red-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0 ">351.00B‬<br></br><span className="text-blue-500">+25.29%</span></th>

              </tr>
              <tr >

                <th className="border-t border-t-grey-15  w-16 px-2  bg-grey-700  left-0 z-40">Total assets</th>
                <th className="border-t border-t-grey-15 p-2 z-0 ">‪290.48B‬<br></br><span className="text-blue-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0">321.69B‬<br></br><span className="text-blue-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0">375.32B‬<br></br><span className="text-red-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0">365.73B‬<br></br><span className="text-blue-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0">‪338.52B<br></br><span className="text-blue-500">+25.29%</span>‬</th>
                <th className="border-t border-t-grey-15 p-2 z-0 ">323.89B‬<br></br><span className="text-red-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0 ">351.00B‬<br></br><span className="text-blue-500">+25.29%</span></th>

              </tr>
              <tr >

                <th className="border-t border-t-grey-15  w-16 px-2  bg-grey-700  left-0 z-40"><span className="relative">Total assets</span></th>
                <th className="border-t border-t-grey-15 p-2 -z-0  ">‪290.48B‬<br></br><span className="text-blue-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 -z-0">321.69B‬<br></br><span className="text-blue-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 -z-0">375.32B‬<br></br><span className="text-red-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 -z-0">365.73B‬<br></br><span className="text-blue-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 -z-0">‪338.52B<br></br><span className="text-blue-500">+25.29%</span>‬</th>
                <th className="border-t border-t-grey-15 p-2 -z-0 ">323.89B‬<br></br><span className="text-red-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 -z-0 ">351.00B‬<br></br><span className="text-blue-500">+25.29%</span></th>

              </tr>

            </tbody>

          </table>

        </div>

      </div> */}








    </div>




  );
}

export default IncomeStatement;
