import { Space, Table } from 'antd';
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { ErrorMessage } from '../../../components/Messages/messages';
import { Layout } from '../../../layout/Layout';

export const AdminContact = () => {
    const [data, setData] = useState([]);
    const getData = async () => {
        await axios.get(`https://bazrbuzz.herokuapp.com/api/contact`).then(res => {
            if (res.statusText === "OK") {
                setData(res.data);
            } else {
                ErrorMessage(res.data.errorMessage);
            }
        })
    }

    useEffect(() => {
        getData()
        return () => {
        }
    }, []);

    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Sub Title',
            dataIndex: 'subTitle',
            key: 'subTitle',
        },
        {
            title: 'Picture',
            key: 'picture',
            render: (_, record) => (
                <Space size="middle">
                    {
                        record?.picture?.type === "video" ?
                            <video src={record.picture.url} />
                            :
                            <img src={record.picture.url} alt={record.title} />
                    }
                </Space>
            ),
        },
        {
            title: 'description',
            key: 'description',
            render: (_, record) => (
                <Space size="middle" className='description-text'>
                    <div dangerouslySetInnerHTML={{ __html: record.description }}></div>
                </Space>
            ),
        },
        {
            title: 'Button TExt',
            dataIndex: 'btnText',
            key: 'btnText',
        },
    ];

    return (
        <Layout sidebar>
            <div className='flex justify-end mt-4 p-4'>
                <Link to="/admin/update-contact" className='bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded'>Update Contact Details</Link>
            </div>
            <div className='blogs'>
                <Table pagination={false} columns={columns} dataSource={data} />
            </div>
        </Layout>
    )
}
