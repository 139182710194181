import { useEffect, useRef } from 'react';
// import './newStockTicker.css';
import React from 'react';
import { useState } from 'react';


function NewStockTicker({ data }) {
    const marqueeRef = useRef(null);
    const [isRunning, setIsRunning] = useState(true);

    useEffect(() => {

    }, [isRunning]);


    const handleMouseEnter = () => {
        if (isRunning) {
            setIsRunning(false)
            marqueeRef.current.stop();
        }
    };

    const handleMouseLeave = () => {
        // if (!isRunning) {
        setIsRunning(true);
        marqueeRef.current.start();
        // }
    };

    return (
        <div className="p-0 m-0 flex items-center justify-center left-0 right-0 top-0 ">
            <div className="wrapper  z-0 pb-0">
                <marquee ref={marqueeRef} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                    <div className={`flex text-red p-1 pb-0 mb-0  space-x-8 m-auto  justify-between`}>
                        {
                            data?.length > 0 && data.map(d => {
                                return (
                                    <section className="inline-block p-1 newsTickter text-white" style={{ fontSize: "17px" }}>
                                        <div className="ml-2 flex items-center">
                                            <span className="text-red"><span className="mr-1">⬤</span>  {d.title}</span>
                                        </div>
                                    </section>
                                )
                            })
                        }
                    </div>
                </marquee>
            </div>
        </div >
    );
}

export default NewStockTicker;