/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/style-prop-object */
import React, { lazy, Suspense, useState } from 'react';
import phone from '../../assets/phone.png';
import { useEffect } from 'react';
import TabContent from '../../components/dashboard/TabContent';
import { ErrorMessage } from '../../components/Messages/messages';
import axios from 'axios';
import BuySellChart from '../../components/BuySellChart';
import Pagination from 'rc-pagination';
import AreaChart from '../../components/AreaChart';
import { FormatNumbers } from '../../components/FormatNumber';
import moment from 'moment';
// import FundamentalsOverview from '../../components/dashboard/FundamentalsOverview';
// import Overview from '../../components/dashboard/Overview';
// import Fundamentals from '../../components/dashboard/Fundamentals';
// import Technicals from '../../components/dashboard/Technicals';
// import CashFlow from '../../components/dashboard/CashFlow';
// import IncomeStatement from '../../components/dashboard/IncomeStatement';
// import BalanceSheet from '../../components/dashboard/BalanceSheet';

// const TabContent = lazy(() => import('../../components/dashboard/TabContent'));


function Dashboard() {

  const [isDropdownOpen, setIsDropDownOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tabSelected, setTabSelected] = useState('Overview');
  const [searchText, setSearchText] = useState('');
  const [filterText, setFilterText] = useState("NIFTY 50");
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const tabSelectedStyle = "inline-block flex items-center justify-content p-2 sm:p-4   text-gray-900 dark:bg-blue-900   active focus:outline-none  dark:text-white";
  const tabUnSelectedStyle = "inline-block p-2 sm:p-4 flex items-center justify-content  text-center text-grey-20 dark:bg-black-800  hover:text-gray-700 hover:bg-gray-50  focus:outline-none dark:hover:text-white  dark:hover:bg-gray-700";
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [selectedStock, setSelectedStock] = useState({});
  const [CSV2Data, setCSV2Data] = useState([]);
  const [selectedCSV2Stock, setSelectedCSV2Stock] = useState({});
  const [perPage, setPerPage] = useState(10);
  const [dropdownText, setDropdownText] = useState("NIFTY 50");
  const [size, setSize] = useState(perPage);
  const [current, setCurrent] = useState(1);

  const PerPageChange = (value) => {
    setSize(value);
    const newPerPage = Math.ceil(filterData.length / value);
    if (current > newPerPage) {
      setCurrent(newPerPage);
    }
  }

  const paginatingData = (current, value) => {
    console.log(value);
    // Normally you should get the data from the server
    if (value && value !== "" && value !== undefined) {
      return filterData.filter(f => f?.symbol?.toLowerCase().includes(value?.toLowerCase()) || f?.name?.toLowerCase().includes(value?.toLowerCase())).slice((current - 1) * 10, current * 10);
    } else {
      return filterData.slice((current - 1) * 10, current * 10);
    }
  };

  const PaginationChange = (page, pageSize) => {
    setCurrent(page);
    setSize(pageSize)
  }

  const PrevNextArrow = (current, type, originalElement) => {
    if (type === 'prev') {
      return <button><i className="fa fa-angle-double-left"></i></button>;
    }
    if (type === 'next') {
      return <button><i className="fa fa-angle-double-right"></i></button>;
    }
    return originalElement;
  }

  const getData = async () => {
    setLoading(true);
    await axios.get(`https://bazrbuzz.herokuapp.com/api/csv/`).then(res => {
      if (res.statusText === "OK") {
        setData(res.data);
        setSelectedStock(res.data[0]);
        setFilterData(res.data);
        if (res.data?.filter(f => f.Symbol === "NSEI").length > 0) {
          localStorage.setItem("AreaChart", JSON.stringify(res.data?.filter(f => f.Symbol === "NSEI")[0]));
        }
      } else {
        ErrorMessage(res.data.errorMessage);
      }
      setLoading(false);
    }).catch(err => setLoading(false))
  }

  const getCSV2Data = async () => {
    setLoading(true);
    await axios.get(`https://bazrbuzz.herokuapp.com/api/csv/2`).then(res => {
      if (res.statusText === "OK") {
        setCSV2Data(res.data);
        setSelectedCSV2Stock(res.data[0]);
        // setFilterCSV2Data(res.data);
        localStorage.setItem("selectedCSV2Stock", JSON.stringify(res.data[0]));
      } else {
        ErrorMessage(res.data.errorMessage);
      }
      setLoading(false);
    })
  }

  useEffect(() => {
    getData();
    getCSV2Data();
    return () => {
    }
  }, []);

  const toggleDropdown = async (f) => {
    setDropdownText(f);
    setFilterText(f);
    let filterIt = data.filter(d => d[f] === "Yes");
    setFilterData(filterIt);
    if (filterData.filter(f => f.Symbol === "NSEI").length > 0) {
      localStorage.setItem("AreaChart", JSON.stringify(filterData.filter(f => f.Symbol === "NSEI")[0]));
    }

    // let filterCSV2It = CSV2Data.filter(d => d[f] === "Yes");
    // setFilterCSV2Data(filterCSV2It);

    setIsDropDownOpen(!isDropdownOpen);
  }

  const close = (e) => {
    setIsDropDownOpen(false);

  }

  useEffect(() => {

    //alert(isSideBarOpen);

  }, [isSideBarOpen]);

  console.log(data)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])


  const filterBySignal = (f) => {
    setCurrent(1)
    setFilterText(f);
    if (f === "null") {
      setFilterData(data);
    }
    else if (f === "All") {
      setFilterData(data);
    } else {
      let filterIt = data.filter(d => d.summary_signal.toLowerCase().includes(f.toLowerCase()));
      setFilterData(filterIt);
    }
  }

  const handleSearchData = (value) => {
    return filterData.filter(f => f?.symbol?.toLowerCase().includes(value.toLowerCase()) || f?.name?.toLowerCase().includes(value.toLowerCase())).slice((current - 1) * 10, current * 10);
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
    paginatingData(current, e.target.value);
  }

  console.log(selectedStock?.uploadTime)

  return (
    loading ?
      <div className='h-[60vh]'>Loading...</div>
      :
      <div className="min-h-screen flex dark font-Rubic dark:bg-black-800 ">
        {/* Sidebar */}
        <div className={`z-10 sidebar m-0 p-0 bg-white overflow-auto text-blue-100   lg:w-90 lg:translate-x-0 space-y-6 dark:bg-black-800 absolute inset-y-0 left-0 transform ${isSideBarOpen === true ? "translate-x-0 w-full" : "-translate-x-full"}  lg:relative  lg:translate-x-0 transition duration-200 ease-in-out`}>
          <div className="flex m-0 p-0 flex-col w-full h-screen px-4 py-6 bg-white b dark:bg-black-800 dark:border-gray-600">
            <div className='flex justify-end sm:hidden'><i className="fa-solid fa-xmark fa-2x" onClick={() => setIsSideBarOpen(!isSideBarOpen)}></i></div>
            {/*  Search Input  */}
            <div className="relative w-full text-gray-600  mt-5 focus-within:text-gray-400">
              <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                <button type="submit" className="p-1 focus:outline-none focus:shadow-outline">
                  <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" className="w-6 h-6"><path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                </button>
              </span>
              <input type="search" name="q" onChange={handleSearchChange} className="py-2 w-full text-sm text-white bg-black-400 rounded-md pl-10 focus:outline-none focus:bg-white focus:text-gray-900" placeholder="Search Stock" autocomplete="off" />
            </div>

            {/*Search Input End */}

            {/* Drop Down Button */}


            {/* <div className="flex mt-5 ml-0 ">
            <div>
              <div className="dropdown relative opacity-100 ">
                <a
                  onClick={() => toggleDropdown(dropdownText)}
                  onBlur={close}
                  style={{ outline: "none", border: "none" }}
                  className="
          :focus {outline:none;border:none}
          dropdown-selector
          dropdown-toggle
          px-6
          opacity-100 
          border-transparent focus:border-transparent focus:ring-0
          py-2.5
          bg-darker-20
          text-white
          font-medium
          text-xs
          leading-tight
          uppercase      
          hover:dark:bg-black-600 hover:shadow-xl
          focus:dark:bg-black-600 focus:shadow-xl focus:outline-0 focus:ring-0
          active:bg-blue-800 active:shadow-xl active:text-white active:ring-0 active:outline-none
          transition
          duration-150
          ease-in-out
          flex
          items-center
          whitespace-nowrap
        "
                  type="button"
                >
                  {dropdownText}
                  <div className='flex justify-end'>
                    <i className="fa-sharp fa-solid fa-sort ml-10"></i>
                  </div>
                </a>
                <ul
                  className={`
          dropdown-menu min-w-max w-full absolute  bg-black-600 rounded-bl-sm rounded-br-sm  outline-none
          hover:outline-none
          focus:outline-none
          
          text-base
          z-50
          float-left
          
          list-none
          text-left
          shadow-xl
          bg-clip-padding
          border-none
          ${isDropdownOpen === true ? "none" : "hidden"}`}
                >
                  <a
                    onClick={() => toggleDropdown("NIFTY 50")}
                    style={{ outline: "none", border: "none" }}
                    className="
          :focus {outline:none;border:none}
          dropdown-selector
          dropdown-toggle
          px-4
          opacity-100 
          border-transparent focus:border-transparent focus:ring-0
          py-2.5
          bg-darker-20
          text-white
          font-medium
          text-xs
          leading-tight
          uppercase      
          hover:dark:bg-black-600 hover:shadow-xl
          focus:dark:bg-black-600 focus:shadow-xl focus:outline-0 focus:ring-0
          active:bg-blue-800 active:shadow-xl active:text-white active:ring-0 active:outline-none
          transition
          duration-150
          ease-in-out
          flex
          items-center
          whitespace-nowrap
        "
                    type="button"
                  >
                    Nifty 50
                  </a>
                  <li>
                    <a
                      onClick={() => toggleDropdown("Sunsex")}
                      className="
              dropdown-item
              text-sm
              py-2
              px-4
              font-normal
              block
              w-full
              whitespace-nowrap
              bg-transparent
              text-white
              hover:bg-green-500
            "
                      href="#"
                    >Sunsex</a>
                  </li>
                  <li>
                    <a
                      onClick={() => toggleDropdown("Nifty Next 50")}
                      className="
              dropdown-item
              text-sm
              py-2
              px-4
              font-normal
              block
              w-full
              whitespace-nowrap
              bg-transparent
              text-white
              hover:bg-green-500
            "
                      href="#"
                    >Nifty Next 50
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => toggleDropdown("Small Cap")}
                      className="
              dropdown-item
              text-sm
              py-2
              px-4
              font-normal
              block
              w-full
              whitespace-nowrap
              bg-transparent
              text-white
              hover:bg-gray-100
            "
                      href="#"
                    >Small Cap</a
                    >
                  </li>
                  <li>
                    <a
                      onClick={() => toggleDropdown("Mid Cap")}
                      className="
              dropdown-item
              text-sm
              py-2
              px-4
              font-normal
              block
              w-full
              whitespace-nowrap
              bg-transparent
              text-white
              hover:bg-gray-100
            "
                      href="#"
                    >Mid Cap</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
            <ul className="grid grid-cols-4 gap-x-3 m-10 mb-5 mt-7    mx-auto max-w-md ">
              <button onClick={() => filterBySignal('Buy')} className='flex p-4 font-bold justify-center pt-2  outline-none pb-2 text-sm bg-blue-500 outline-none text-center border-none text-white   rounded-sm cursor-pointer focus:outline-none  focus:border-none focus:bg-white focus:text-black-900' for="Buy">Buy</button>
              <button onClick={() => filterBySignal('Sell')} className="flex p-4  font-bold justify-center pt-2 pb-2 text-sm bg-red-500 text-right border-none text-white border  rounded-sm cursor-pointer focus:outline-none  outline-none  focus:bg-white focus:text-black-900" for="Sell">Sell</button>
              <button onClick={() => filterBySignal('Neutral')} className="flex p-4 font-bold justify-center pt-2 pb-2 text-sm bg-orange-500 text-center border-none text-white border  rounded-sm cursor-pointer focus:outline-none   focus:bg-white focus:text-black-900 " for="Neutral">Neutral</button>
              <button onClick={() => filterBySignal('All')} className="flex p-4 font-bold justify-center text-sm  pt-2 pb-2 bg-black-400 text-center border-none text-white border  rounded-sm cursor-pointer focus:outline-none   focus:bg-white focus:text-black-900 " for="All">All</button>
            </ul>
            <table className="w-full text-sm">
              <thead className=" text-black-100 text-sm">
                <tr className="">
                  <th className="text-left pl-2 pb-5">NAME </th>
                  <th className="text-left pl-2 pb-5">CHANGE</th>
                  <th className="text-left pl-2 pb-5">SIGNAL</th>
                </tr>
              </thead>
              <tbody className="text-left">
                {/* .filter(d => d[filterText] === "Yes" || d?.summary_signal.toLowerCase().includes(signalSelected.toLowerCase())) */}
                {
                  filterData.filter(f => f?.symbol?.toLowerCase().includes(searchText.toLowerCase()) || f?.name?.toLowerCase().includes(searchText.toLowerCase())).slice((current - 1) * 10, current * 10).map(d => {
                    console.log(Math.sign(d.change))
                    return (
                      <tr className={`hover:bg-black-50 signalClass ${d?.summary_signal?.replace(/\s+/g, '-').toLowerCase()}`} onClick={() => { setLoading(true); setSelectedStock(d); setSelectedCSV2Stock(CSV2Data.filter(f => f.Symbol === d.Symbol)[0]); selectedCSV2Stock?.Stock === "No" && setTabSelected("Overview"); setIsSideBarOpen(false); setLoading(false) }}>
                        <td className="p-2 text-black-50 rounded-tl-md rounded-bl-md"><span className="font-bold text-black-100">{d.Symbol} </span><br></br><span className="text-sm text-black-100 companyName">{d.name}</span></td>
                        <td className="p-2 text-black-100"><span className='text-black-100'>₹ {FormatNumbers(d.close)}</span><br></br><span className={`font-bold ${Math.sign(d.change) === 1 ? "buyGreen" : "sell"}`}>{d.change}</span></td>
                        <td className="p-2 rounded-tr-md rounded-br-md ">{d.summary_signal}</td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
            <div className='pagination-parent'>
              <Pagination
                className="pagination-data mt-5"
                // showTotal={(total, range) => `Showing ${range[0]}-${range[1]} of ${total}`}
                onChange={PaginationChange}
                total={filterData?.filter(f => f?.symbol?.toLowerCase().includes(searchText.toLowerCase()) || f?.name?.toLowerCase().includes(searchText.toLowerCase()))?.length}
                current={current}
                pageSize={size}
                showSizeChanger={false}
                itemRender={PrevNextArrow}
                onShowSizeChange={PerPageChange}
              />
            </div>
          </div>
        </div>
        {/* Sidebar End */}

        {/* Main Content */}

        <div className="z-0 dark:bg-black-800 flex-1 pb-1 pl-2 pr-2 pt-3 lg:pt-0  m-0 overflow-hidden  content">
          <div className="flex items-center block lg:hidden mb-2 lg:mb-0">
            {/* <button src={phone} className="block lg:hidden" onClick={() => setIsSideBarOpen(!isSideBarOpen)}>
            <svg className="ml-1 " width="23" height="14" viewBox="0 0 32 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="32" height="3" rx="1.5" fill="#01E4A1" />
              <rect y="8" width="32" height="3" rx="1.5" fill="#01E4A1" />
              <rect y="16" width="32" height="3" rx="1.5" fill="#01E4A1" />
            </svg>
          </button> */}
            <div className="relative w-full text-gray-600  mt-0 focus-within:text-gray-400">
              <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                <button type="submit" className="p-1 focus:outline-none focus:shadow-outline">
                  <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" className="w-6 h-6"><path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                </button>
              </span>
              <input type="search" value={selectedStock?.name} name="q" onClick={() => setIsSideBarOpen(!isSideBarOpen)} className="py-2 w-full text-sm text-white bg-black-400 rounded-md pl-10 focus:outline-none focus:bg-white focus:text-gray-900" placeholder="Search Stock" autocomplete="off" />
            </div>
            {/* <input type="search" value={selectedStock?.name} name="q" onChange={handleSearchChange} className="py-2 w-full text-sm text-white bg-black-400 rounded-md pl-10 focus:outline-none focus:bg-white focus:text-gray-900" placeholder="Search Stock" autocomplete="off" /> */}
            {/* <h2 className="text-lg font-semibold text-gray-800 text-left ml-5 dark:text-white ">
            <span className="dark:text-white ">BAZR</span>
            <span className="text-green-800">BUZZ</span>
          </h2> */}
          </div>
          <p className='text-white text-lg'>BETA: Stock Analysis Reimagined, Work with us as we improve</p>
          <div className='hidden sm:block'>
            <div className="grid my-5 md:grid-cols-3 sm:grid-cols-1 gap-4 mt-1 sm:mt-0">
              <AreaChart filterData={data.filter(d => d.Symbol === "NSEI")[0]} title="Buy/Sell Signal" />
              {/* <BuySellChart filterData={filterData} title="Buy/Sell Signal" /> */}
              <BuySellChart
                filterData={data}
                value1={data.filter(d => d.percent_change > 0).length}
                value2={data.filter(d => d.percent_change < 0).length}
                title="Stock Moves"
              />
              <BuySellChart
                buySell={true}
                filterData={data}
                value1={data?.filter(d => d.summary_signal.includes("Buy")).length}
                value2={data.filter(d => d.summary_signal.includes("Sell")).length}
                title="Buy/Sell Signal"
              />
            </div>
          </div>
          {/* Item Details */}
          <div className="grid grid-cols-1 bg-black-700 rounded-md w-full  px-0 sm:px-4 py-2 mt-2">
            <div className="grid md:grid-flow-col w-full md:auto-cols-max md:gap-5">
              <div className="leading-5 p-2 px-0 md:p-3 flex justify-between">
                <div>
                  <span className={`text-grey-100 text-xl`}>{selectedStock?.symbol}</span><br />
                  <span className={`text-xs text-grey-100`}>{selectedStock?.name}</span>
                </div>
                <div className="md:py-7 p-2 block sm:hidden">
                  <span className={`${selectedStock?.summary_signal?.replace(/\s+/g, '-').toLowerCase()} w-4 h-4 bg-green-10 transparent rounded-md px-3 py-2`}>{selectedStock?.summary_signal}</span>
                </div>
              </div>
              <div className='flex justify-between sm:block'>
                <div className="leading-5 p-2 px-0 md:p-4">
                  <span className={`${Math.sign(selectedStock?.change) === -1 ? "sellSignal" : "buySignal"} text-lg`}>{selectedStock?.currency} {FormatNumbers(selectedStock?.close)}</span><br />
                  {/* <span className={`${selectedStock?.summary_signal?.replace(/\s+/g, '-').toLowerCase()} text-lg`}>{selectedStock?.currency} {FormatNumbers(selectedStock?.close)}</span><br /> */}
                  <span className="text-xs text-grey-100">{moment(selectedStock?.uploadTime, "MMMM Do YYYY, hh:mm:ss a").format("MMM DD, YYYY")}</span>
                  {/* moment(date, "MMMM Do YYYY, hh:mm:ss a").format("MMM DD, YYYY") */}
                </div>
                <div className="leading-5 md:p-4 p-2 block sm:hidden">
                  <span className={`${Math.sign(selectedStock?.change) === -1 ? "sellSignal" : "buySignal"} text-lg`}>{selectedStock?.change}</span><br />
                  <span className={`text-xs ${Math.sign(selectedStock?.change) === -1 ? "sellSignal" : "buySignal"}`}>{selectedStock?.percent_change}%</span>
                </div>
              </div>
              <div className="leading-5 md:p-4 p-2 hidden sm:block">
                <span className={`${Math.sign(selectedStock?.change) === -1 ? "sellSignal" : "buySignal"} text-lg`}>{selectedStock?.change}</span><br />
                <span className={`text-xs ${Math.sign(selectedStock?.change) === -1 ? "sellSignal" : "buySignal"}`}>{selectedStock?.percent_change}%</span>
              </div>
              <div className="md:py-7 p-2 hidden sm:block">
                <span className={`${selectedStock?.summary_signal?.replace(/\s+/g, '-').toLowerCase()} w-4 h-4 bg-green-10 transparent rounded-md px-3 py-2`}>{selectedStock?.summary_signal}</span>
              </div>
            </div>
            {/* tabs */}
            <div className="mainMenu text-sm mt-2 font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
              <ul className="grid w-full overflow-auto grid-cols-12 dark:bg-black-800 rounded-tl-md rounded-tr-md mt-4  text-sm font-medium text-center text-gray-500 shadow sm:flex dark:divide-gray-700 dark:text-gray-400">
                <button onClick={() => setTabSelected('Overview')} className={(tabSelected === 'Overview') ? tabSelectedStyle : tabUnSelectedStyle} >Latest</button>
                {
                  selectedCSV2Stock?.Stock === "Yes" &&
                  <button onClick={() => setTabSelected('Fundamentals')} className={(tabSelected === 'Fundamentals') ? tabSelectedStyle : tabUnSelectedStyle} >Fundamentals</button>
                }
                <button onClick={() => setTabSelected('Technicals')} className={(tabSelected === 'Technicals') ? tabSelectedStyle : tabUnSelectedStyle} >Technicals</button>
              </ul>
            </div>
            {
              loading ?
                <div>Loading...</div>
                :
                selectedCSV2Stock?.Stock === "Yes" ?
                  <TabContent tabSelected={tabSelected} selectedStock={selectedStock} selectedCSV2Stock={selectedCSV2Stock} />
                  :
                  <TabContent tabSelected={tabSelected} selectedStock={selectedStock} selectedCSV2Stock={selectedCSV2Stock} />
            }
            <div className='block sm:hidden'>
              <div className="grid my-5 md:grid-cols-3 sm:grid-cols-1 gap-4 mt-1 sm:mt-0">
                <AreaChart filterData={data.filter(d => d.Symbol === "NSEI")[0]} title="Buy/Sell Signal" />
                {/* <BuySellChart filterData={filterData} title="Buy/Sell Signal" /> */}
                {/* <BuySellChart filterData={data} title="Stock Moves" />
                <BuySellChart buySell={true} filterData={data} title="Buy/Sell Signal" /> */}
                <BuySellChart
                  filterData={data}
                  value1={data.filter(d => d.percent_change > 0).length}
                  value2={data.filter(d => d.percent_change < 0).length}
                  title="Stock Moves"
                />
                <BuySellChart
                  buySell={true}
                  filterData={data}
                  value1={data?.filter(d => d.summary_signal.includes("Buy")).length}
                  value2={data.filter(d => d.summary_signal.includes("Sell")).length}
                  title="Buy/Sell Signal"
                />
              </div>
            </div>
          </div>
          {/* Item Details End*/}
        </div>
        {/* Main Content End */}
      </div >
  );
}

export default Dashboard;
