/* eslint-disable jsx-a11y/alt-text */
//
import dashboard from '../../assets/dashboard.svg';
import analytics from '../../assets/analytics.svg';
import educational from '../../assets/educational.svg';
import dataSvg from '../../assets/data.svg';
import writtenanalysis from '../../assets/writtenanalysis.svg';
import interactivedashboard from '../../assets/interactivedashboard.svg';
import { Link } from "react-router-dom";
import NewsTicker from '../../components/home/NewsTicker';
import { useEffect, useState } from 'react';
import { ErrorMessage } from '../../components/Messages/messages';
import axios from 'axios';
import BlogCard from '../../components/BlogCard';
import EducationSlides from '../../components/EducationSlides';
import "./Home.css"
import { FormatNumbers } from '../../components/FormatNumber';
import NewStockTicker from '../../components/home/NewNewsTicker/NewNewsTicker';

function Home() {
  const [data, setData] = useState([]);
  const [blogs, setBlogs] = useState([]);


  const getData = async () => {
    await axios.get(`https://bazrbuzz.herokuapp.com/api/csv/`).then(res => {
      if (res.statusText === "OK") {
        setData(res.data);
      } else {
        ErrorMessage(res.data.errorMessage);
      }
    })
  }

  const getBlogs = async () => {
    await axios.get(`https://bazrbuzz.herokuapp.com/api/blogs`).then(res => {
      if (res.statusText === "OK") {
        setBlogs(res.data);
      } else {
        ErrorMessage(res.data.errorMessage);
      }
    })
  }

  useEffect(() => {
    getData();
    getBlogs();
    return () => {
    }
  }, []);

  return (
    <div className="dark font-Rubic homepage">
      <div className="dark:bg-black-800 min-h-screen">
        <div className="md:container md:mx-auto sm:m-5 sm:mt-0 mt-0 pl-2  pr-2 sm:p-0">
          <div className="grid md:grid-cols-2 gap-4 ">
            <div className="bg-black-900  rounded-lg p-5 dark:bg-black-700">
              <h1 className="text-white font-bold text-green-500">Technical Buy</h1>
              <div className="tableContainer">
                <table className="text-sm mt-3">
                  <thead className=" text-black-100 text-sm text-white ">
                    <tr className="">
                      <th className="sm:pl-2 text-left companyNameColumn pb-5">NAME</th>
                      <th className="pl-2 text-left pb-5">LAST</th>
                      <th className="pl-2 text-left  pb-5">CHANGE %</th>
                      <th className="pl-2 text-left  pb-5">SIGNAL</th>
                    </tr>
                  </thead>
                  <tbody className="text-white">
                    {
                      data?.length > 0 && data.filter(d => d?.summary_signal?.includes("Strong Buy")).slice(0, 5).map(d => {
                        return (
                          <tr className={`signalClass ${d?.summary_signal?.toLowerCase().includes("sell") || Math.sign(d.change) === -1 && "sellSignal"}`}>
                            {/* <tr className={`signalClass ${d?.summary_signal?.toLowerCase().includes("sell") && "sellSignal"}`}> */}
                            <td className="pl-0 sm:p-2 text-gray-600 companyName">{d.name}</td>
                            <td className="pl-1 sm:p-2" style={{ color: "rgb(150 156 181 / 1)" }}>{FormatNumbers(d.close)}</td>
                            <td className={`pl-2 sm:p-2 text-gray-600`}>{d.percent_change}%</td>
                            <td className={`pl-2 sm:p-2 ${d?.summary_signal?.includes("Sell") && "sellSignal"} ${d?.summary_signal?.includes("Buy") && "buySignal"}`}>{d.summary_signal}</td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>
            </div>
            <div className="bg-black-900  rounded-lg p-5 dark:bg-black-700">
              <h1 className="text-white font-bold text-red-500">Technical Sell</h1>
              <div className="tableContainer">
                <table className="w-full text-sm mt-3">
                  <thead className=" text-black-100 text-sm text-white ">
                    <tr className="">
                      <th className="sm:pl-2 text-left pb-5 companyNameColumn">NAME</th>
                      <th className="pl-2 text-left pb-5">LAST</th>
                      <th className="pl-2 text-left  pb-5">CHANGE %</th>
                      <th className="pl-2 text-left  pb-5">SIGNAL</th>
                    </tr>
                  </thead>
                  <tbody className="text-white">
                    {
                      data?.length > 0 && data.filter(d => d?.summary_signal?.includes("Strong Sell")).slice(0, 5).map(d => {
                        return (
                          <tr className={`signalClass ${d?.summary_signal?.toLowerCase().includes("sell") && "sellSignal"}`}>
                            <td className="pl-0 sm:p-2 companyName">{d.name}</td>
                            <td className="pl-1 sm:p-2" style={{ color: "rgb(150 156 181 / 1)" }}>{FormatNumbers(d.close)}</td>
                            <td className=" pl-2 sm:p-2 text-green-500">{d.percent_change}%</td>
                            <td className="pl-2 sm:p-2 text-green-500">{d.summary_signal}</td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="dark:bg-black-700 p-5 mt-5">
          <div className="md:container md:mx-auto sm:m-5 mt-5  ">
            <h2 className="pt-4 font-bold text-white mb-2">Latest Updates</h2>
            <NewStockTicker
              data={data.filter(d => d?.summary_signal?.includes("Strong Buy")).slice(0, 5)}
              dataSell={data.filter(d => d?.summary_signal?.includes("Strong Sell")).slice(0, 5)}
            />
          </div>
          {/* Slider End */}
        </div>
        <div className=" p-5">
          <div className="md:container md:mx-auto sm:m-5 mt-5  ">
            {/*Slider */}
            <div className=" grid lg:grid-cols-2 mt-4">
              <section className="order-2 lg:order-1  ">
                <h1 className="pt-4 tradingTitle font-bold text-white mt-4 mb-2">Trading Dashboard</h1>
                <p className="text-grey-40 text-md mt-0 mb-6" style={{ fontSize: "16px" }}>
                  We offer comprehensive buy and sell recommendations based on our technical analysis algorithm. Our algorithm performs extensive big data analysis of hundreds of stocks to facilitate easier and simpler investment decision-making.
                </p>
                <Link to="/dashboard" className="bg-green-800 pt-2 pb-2 mt-3 pl-7 pr-7 text-md text-white rounded-sm mt-5">Check it out </Link>
              </section>
              <section className="order-1 lg:order-2  ">
                <img src={dashboard} className="object-cover h-200 w-full" />
              </section>
            </div>
          </div>
          {/* Slider End */}
        </div>
        <div className=" p-1">
          <div className="md:container md:mx-auto sm:m-5 mt-1   ">
            {/*Slider */}
            <div className=" grid md:grid-cols-2 mt-4 gap-5">
              <section className="sm:block hidden">
                <img src={analytics} className="object-cover h-200 w-full" />
              </section>
              <section className="iconsSection">
                <div className="grid md:grid-cols-2 max-h-fit gap-4 overflow-auto ">
                  <div className="grid grid-cols-1 md:pb-7">
                    <div className="mb-4 p-5 rounded-md dark:bg-black-700 text-center content-center">
                      <img src={dataSvg} className=" mx-auto  pb-5" />
                      <h4 className="text-lg text-white">Technical and Fundamental Data</h4>
                    </div>
                    <div className="mb-4 p-5 rounded-md dark:bg-black-700">
                      <img src={writtenanalysis} className=" mx-auto  pb-5" />
                      <h4 className="text-lg text-white text-center"> Simplified Written Analysis</h4>
                    </div>
                  </div>
                  <div className="grid grid-cols-1 md:pt-7">
                    <div className="mb-4 p-5 rounded-md dark:bg-black-700">
                      <img src={interactivedashboard} className=" mx-auto pb-5" />
                      <h4 className="text-lg text-white text-center"> Interactive<br></br> Dashboard</h4>
                    </div>
                    <div className="mb-4 p-5 rounded-md dark:bg-black-700">
                      <img src={educational} className=" mx-auto  pb-5" />
                      <h4 className="text-md text-white text-center"> Educational Videos and Articles</h4>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
          {/* Slider End */}
        </div>
        <div className=" dark:bg-black-700 mx-auto">
          <div>
            <h1 className="pt-4 text-xl font-bold text-center text-white mt-6 mb-6">Educational Videos</h1>
            <div className='py-10'>
              <EducationSlides data={blogs.filter(d => d?.picture?.type === "video")} />
            </div>
          </div>
        </div>
        <div className="container mx-auto p-2" >
          <h1 className="pt-4 text-xl font-bold text-center text-white mt-6 mb-6">Educational Blog</h1>
          <div className="flex flex-wrap justify-center gap-5">
            {
              blogs?.length > 0 && blogs.filter(d => d?.picture?.type === "image").map(b => {
                return (
                  <div className='eachBlog'>
                    <BlogCard data={b} />
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </div >
  );

}
export default Home;