import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Pagination, Navigation } from "swiper";
import { Link } from 'react-router-dom';

const EducationSlides = ({ data }) => {
    return (
        <Swiper
            slidesPerView={3}
            spaceBetween={30}
            slidesPerGroup={1}
            longSwipes
            loop={true}
            centerInsufficientSlides={true}
            centeredSlides={true}
            initialSlide={2}
            loopFillGroupWithBlank={true}
            pagination={{
                clickable: true,
            }}
            navigation={true}
            modules={[Pagination, Navigation]}
            className="mySwiper"
        >
            {
                data.map(d => {
                    return (
                        <SwiperSlide>
                            <Link to={"/blog/" + d._id}>
                                <video src={d?.picture?.url} controls className='w-100' />
                            </Link>
                        </SwiperSlide>
                    )
                })
            }
        </Swiper>
    )
}

export default EducationSlides
