/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/style-prop-object */
import React from 'react';
import ReactECharts from 'echarts-for-react';

function CashFlow() {


  return (
    <div className="overflow-hidden ">
      {/* <div className="text-grey-20  p-4  bg-grey-10">
        <h4 className="text-sm mb-3 font-bold text-white">An in-depth look to Apple Inc operating, investing, and financing activities
        </h4>
        <p className="text-sm">AAPL free cash flow for Q1 22 is 44.16B USD. For 2021, AAPL free cash flow was 92.95B USD and operating cash flow was 104.04B USD.


        </p>
      </div> */}
      <div className="m-3">
        <button className=" bg-grey-5 rounded-md px-2 py-0.5 text-sm mr-5 text-grey-20 ">Annual</button>
        <button className=" text-grey-20 text-sm dark:hover:text-white"> Quarterly</button>
      </div>
      <div className="grid md:grid-cols-1 ">


        <div className="w-full h-auto p-1 ">
          <ReactECharts



            option={{
              height: '180px',


              title: {
                text: 'Cash flow',
                textStyle: {
                  color: '#d1d4dc',
                  fontSize: '16px',


                }

              },
              tooltip: {
                trigger: 'axis'
              },
              legend: {
                data: ['Actual', 'Estimate'],
                top: '88%',
                padding: 6
              },
              grid: {
                left: '4%',
                right: '4%',
                bottom: '3%',
                containLabel: true,
              },
              toolbox: {
                feature: {
                  saveAsImage: { show: false }
                }
              },
              xAxis: {
                type: 'category',
                boundaryGap: true,
                data: ['2017', '2018', '2019', '2020', '2021']
              },
              yAxis: {
                type: 'value',
                position: 'right',
                nameGap: 1,


              },
              series: [
                {
                  name: 'Actual',
                  type: 'line',
                  data: [0.95, 1.25, 1.55, 1.89, 1.95]
                },
                {
                  name: 'Estimate',
                  type: 'line',
                  data: [0.77, 1.10, 1.25, 1.70, 1.8]
                },

              ]
            }} />
        </div>

      </div>


      {/*
    <div class=" overflow-x-auto  max-w-sm m-auto	 md:max-w-full  text-sm text-grey-120 ">
            <div class="">
            <table class="  max-w-sm m-auto	 md:max-w-full whitespace-nowrap  overflow-auto" >
                <thead class=" whitespace-nowrap">
                    <tr class="whitespace-nowrap ">
                    <th   class="text-grey-110 font-normal border-t  border-t-grey-15 w-1/4  bg-grey-700 px-2 left-0 z-40  ">Currency: USD</th>
                    <th class="border-t border-t-grey-15 p-2  text-grey-110  font-normal 	  bg-grey-700 -z-0">dddddddd2015</th>
                    <th class="border-t border-t-grey-15 p-2  text-grey-110  font-normal 	 -z-0">dddddddddd2016</th>
                    <th class="border-t border-t-grey-15 p-2  text-grey-110  font-normal 	 -z-0">2ddddddddddd017</th>
                    <th class="border-t border-t-grey-15 p-2  text-grey-110 font-normal  	-z-0">2dddd018</th>
                    <th class="border-t border-t-grey-15 p-2  text-grey-110 font-normal  	 -z-0">2dddddddddd019</th>
                    <th class="border-t border-t-grey-15 p-2  text-grey-110 font-normal  	 -z-0">20ddddd20</th>
                    <th class="border-t border-t-grey-15 p-2 text-grey-110 font-normal  	 -z-0">20ddddddd21</th>
                    </tr>


                </thead>
                <tbody class=" ">
                    <tr class=" ">

                    <th  class="border-t border-t-grey-15  px-2 px-4 bg-grey-700  w-1/2 left-0 z-40 whitespace-nowrap">Total liabilities
</th>
                    <th class="border-t border-t-grey-15 p-2 z-0  whitespace-nowrap  	 ">‪290.48B‬<br></br><span class="text-blue-500">+25.29%</span></th>
                    <th class="border-t border-t-grey-15 p-2 z-0 whitespace-nowrap 	">321.69B‬<br></br><span class="text-blue-500">+25.29%</span></th>
                    <th class="border-t border-t-grey-15 p-2 z-0  whitespace-nowrap 	">375.32B‬<br></br><span class="text-red-500">+25.29%</span></th>
                    <th class="border-t border-t-grey-15 p-2 z-0 whitespace-nowrap 	">365.73B‬<br></br><span class="text-blue-500">+25.29%</span></th>
                    <th class="border-t border-t-grey-15 p-2 z-0 whitespace-nowrap 	">‪338.52B<br></br><span class="text-blue-500">+25.29%</span>‬</th>
                    <th class="border-t border-t-grey-15 p-2 z-0  whitespace-nowrap 	">323.89B‬<br></br><span class="text-red-500">+25.29%</span></th>
                    <th class="border-t border-t-grey-15 p-2 z-0  whitespace-nowrap 	">351.00B‬<br></br><span class="text-blue-500">+25.29%</span></th>

                    </tr>
             




                </tbody>
            
            </table>

            </div>

        </div>

        */}


      <div className="overflow-x-auto max-w-sm m-auto	 md:max-w-full  text-sm text-grey-120  whitespace-nowrap	">
        <div className="">
          <table className=" w-full max-w-sm m-auto	 md:max-w-full  whitespace-nowrap overflow-auto " >
            <thead className=" whitespace-nowrap">
              <tr className="whitespace-nowrap relative z-0 ">
                <th className="text-grey-110 font-normal border-t px-4  border-t-grey-15 w-16   bg-grey-700  left-0 z-40  ">Currency: USD</th>
                <th className="border-t border-t-grey-15 p-2  text-grey-110  font-normal 	   -z-0">2015</th>
                <th className="border-t border-t-grey-15 p-2  text-grey-110  font-normal   	 -z-0">2016</th>
                <th className="border-t border-t-grey-15 p-2  text-grey-110  font-normal 	 -z-0">2017</th>
                <th className="border-t border-t-grey-15 p-2  text-grey-110 font-normal   	-z-0">2018</th>
                <th className="border-t border-t-grey-15 p-2  text-grey-110 font-normal   	 -z-0">2019</th>
                <th className="border-t border-t-grey-15 p-2  text-grey-110 font-normal  	 -z-0">2020</th>
                <th className="border-t border-t-grey-15 p-2 text-grey-110 font-normal   	 -z-0">2021</th>
              </tr>


            </thead>
            <tbody className=" whitespace-nowrap">
              <tr className="whitespace-nowrap ">

                <th className="border-t border-t-grey-15  px-4  w-16 left-0 z-9999 whitespace-nowrap"><p className="sm:w-48 overflow-hidden text-ellipsis">Cash from operating activities</p>
                </th>
                <th className="border-t border-t-grey-15 p-2 z-0  whitespace-nowrap  	 ">‪290.48B‬<br></br><span className="text-blue-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0 whitespace-nowrap 	">321.69B‬<br></br><span className="text-blue-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0  whitespace-nowrap 	">375.32B‬<br></br><span className="text-red-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0 whitespace-nowrap 	">365.73B‬<br></br><span className="text-blue-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0 whitespace-nowrap 	">‪338.52B<br></br><span className="text-blue-500">+25.29%</span>‬</th>
                <th className="border-t border-t-grey-15 p-2 z-0  whitespace-nowrap 	">323.89B‬<br></br><span className="text-red-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0  whitespace-nowrap 	">351.00B‬<br></br><span className="text-blue-500">+25.29%</span></th>

              </tr>
              <tr >

                <th className="border-t border-t-grey-15  w-16  px-4  bg-grey-700  left-0 z-40 overflow-hidden text-ellipsis"><p className="sm:w-48 overflow-hidden text-ellipsis">Cash from investing activities</p>
                </th>
                <th className="border-t border-t-grey-15 p-2 z-0 ">‪290.48B‬<br></br><span className="text-blue-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0">321.69B‬<br></br><span className="text-blue-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0">375.32B‬<br></br><span className="text-red-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0">365.73B‬<br></br><span className="text-blue-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0">‪338.52B<br></br><span className="text-blue-500">+25.29%</span>‬</th>
                <th className="border-t border-t-grey-15 p-2 z-0 ">323.89B‬<br></br><span className="text-red-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0 ">351.00B‬<br></br><span className="text-blue-500">+25.29%</span></th>

              </tr>
              <tr >

                <th className="border-t border-t-grey-15  w-16 px-4  bg-grey-700  left-0 z-40  	overflow-hidden text-ellipsis" alt="Total liabilities & shareholders' equities"><p className="sm:w-48 overflow-hidden text-ellipsis">Cash from financial activities</p>
                </th>
                <th className="border-t border-t-grey-15 p-2 -z-0 ">‪290.48B‬<br></br><span className="text-blue-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0">321.69B‬<br></br><span className="text-blue-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0">375.32B‬<br></br><span className="text-red-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0">365.73B‬<br></br><span className="text-blue-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0">‪338.52B<br></br><span className="text-blue-500">+25.29%</span>‬</th>
                <th className="border-t border-t-grey-15 p-2 z-0 ">323.89B‬<br></br><span className="text-red-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0 ">351.00B‬<br></br><span className="text-blue-500">+25.29%</span></th>

              </tr>
              <tr >

                <th className="border-t border-t-grey-15  w-16 px-4  bg-grey-700  left-0 z-40 "><p className="sm:w-48 overflow-hidden text-ellipsis">Free cash flow</p>
                </th>
                <th className="border-t border-t-grey-15 p-2 z-0 ">‪290.48B‬<br></br><span className="text-blue-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0">321.69B‬<br></br><span className="text-blue-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0">375.32B‬<br></br><span className="text-red-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0">365.73B‬<br></br><span className="text-blue-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0">‪338.52B<br></br><span className="text-blue-500">+25.29%</span>‬</th>
                <th className="border-t border-t-grey-15 p-2 z-0 ">323.89B‬<br></br><span className="text-red-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0 ">351.00B‬<br></br><span className="text-blue-500">+25.29%</span></th>

              </tr>




            </tbody>

          </table>

        </div>

      </div>








    </div>




  );
}

export default CashFlow;
