/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/style-prop-object */



import React, { useState } from 'react';
import ReactECharts from 'echarts-for-react';

import { isCompositeComponentWithType } from 'react-dom/test-utils';
import TabContent from './TabContent';
function Fundamentals() {
    const [subTabSelected, setSubTabSelected] = useState('FundamentalsOverview');
    const tabSelectedStyle = "inline-block p-3  text-sm text-blue-600 rounded-t-lg border-b-2 border-blue-600 active dark:text-blue-500 dark:border-blue-500";
    const tabUnSelectedStyle = "inline-block  p-3 text-sm text-grey-20 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300";
    //const tabSelectedStyle="inline-block flex items-center justify-content p-2 sm:p-4   text-gray-900 dark:bg-blue-900   active focus:outline-none  dark:text-white";
    //const tabUnSelectedStyle="inline-block p-2 sm:p-4 flex items-center justify-content  text-center text-grey-20 dark:bg-black-800  hover:text-gray-700 hover:bg-gray-50  focus:outline-none dark:hover:text-white  dark:hover:bg-gray-700";

    return (
        <div className="grid md:grid-cols-3 mt-5 ">
            <div className="md:col-span-3 ">
                {/* <div className="sm:flex  grid grid-cols-2 gap-0">
                    <div className=" sm:flex-shrink-0   text-xs text-green-800 w-fit text-left h-auto bg-green-10 transparent rounded-md px-3 py-2 m-1"><span>Short Term Buy Signal </span></div>
                    <div className="sm:flex-shrink-0   text-xs text-green-800 w-fit text-left h-auto bg-green-10 transparent rounded-md px-3 py-2 m-1">Outerforms Market</div>
                    <div className="sm:flex-shrink-0   text-xs text-green-800 w-fit text-left h-auto bg-green-10 transparent rounded-md px-3 py-2 m-1">Moving Average Crossover</div>
                    <div className="sm:flex-shrink-0  text-xs text-green-800 w-fit  text-left h-auto bg-green-10 transparent rounded-md px-3 py-2 m-1">Near 52 High</div>

                </div> */}
                <div className="dark:bg-black-800 rounded-md ">
                    {/*
                <ul class="grid w-full overflow-auto grid-cols-4 dark:bg-black-800 rounded-tl-md rounded-tr-md mt-4  text-sm font-medium text-center text-gray-500  divide-x divide-gray-200 shadow sm:flex dark:divide-gray-700 dark:text-gray-400">
   
        <button onClick={()=>setSubTabSelected('FundamentalsOverview')} class={(subTabSelected==='FundamentalsOverview')? tabSelectedStyle: tabUnSelectedStyle} >Overview</button>
  
  
        <button onClick={()=>setSubTabSelected('IncomeStatement')} class={(subTabSelected==='IncomeStatement')? tabSelectedStyle: tabUnSelectedStyle} >Income Statement</button>
  
 
        <button onClick={()=>setSubTabSelected('BalanceSheet')} class={(subTabSelected==='BalanceSheet')? tabSelectedStyle: tabUnSelectedStyle} >Balance Sheet</button>
    
    
        <button onClick={()=>setSubTabSelected('CashFlow')} class={(subTabSelected==='CashFlow')? tabSelectedStyle: tabUnSelectedStyle} >Cash Flow</button>
   
</ul>
                    */}


                    <ul class="flex flex-wrap -mb-px">


                        <button onClick={() => setSubTabSelected('FundamentalsOverview')} class={(subTabSelected === 'FundamentalsOverview') ? tabSelectedStyle : tabUnSelectedStyle} >Overview</button>

                        {/* 
                        <button onClick={() => setSubTabSelected('IncomeStatement')} class={(subTabSelected === 'IncomeStatement') ? tabSelectedStyle : tabUnSelectedStyle} >Income Statement</button>


                        <button onClick={() => setSubTabSelected('BalanceSheet')} class={(subTabSelected === 'BalanceSheet') ? tabSelectedStyle : tabUnSelectedStyle} >Balance Sheet</button>


                        <button onClick={() => setSubTabSelected('CashFlow')} class={(subTabSelected === 'CashFlow') ? tabSelectedStyle : tabUnSelectedStyle} >Cash Flow</button> */}
                    </ul>
                    <TabContent tabSelected={subTabSelected} />
                </div>
            </div>
        </div>
    );
}

export default Fundamentals;
