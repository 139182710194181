import React, { useState } from 'react'
import axios from 'axios';
import { Link } from 'react-router-dom';
import { ErrorMessage, SuccessMessage } from '../../../components/Messages/messages';
import { Loading } from '../../../components/Loading/Loading';
import ReactQuill from 'react-quill';
import { Layout } from '../../../layout/Layout';
import EditorToolbar, { formats, modules } from '../../../components/QuillEditor';


export const CreateBlogsForm = () => {
    const [file, setFile] = useState('');
    const [loading, setLoading] = useState(false);
    const [description, setDescription] = useState("");
    const [productData, setProductData] = useState({
        title: '',
        subTitle: ''
    });

    const { title, subTitle } = productData;

    /***********************************************onChange *******************************************/
    const handleCarChange = (e) => {
        setProductData({
            ...productData,
            [e.target.name]: e.target.value
        });
    }

    const handleImageChange = (e) => {
        setFile(e.target.files[0])
    }

    /************************************************ Submit **********************************************/

    const submitHandler = (e) => {
        e.preventDefault();
        setLoading(true);
        let data = new FormData();
        data.append('title', title);
        data.append('subTitle', subTitle);
        data.append('description', description);
        data.append('file', file);
        axios.post(`https://bazrbuzz.herokuapp.com/api/blogs/upload`, data, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('token')
            }
        }).then(res => {
            if (res.statusText === "OK") {
                setLoading(false);
                SuccessMessage(res.data.successMessage);
            }
            else {
                ErrorMessage(res.data.errorMessage);
            }
        })
    }
    console.log(file)


    return (
        <Layout sidebar>
            <div className='w-100 p-4 blogs-form mb-4' style={{ marginTop: '10px', paddingTop: '47px', background: '#FFFFFF', boxShadow: '10px 10px 30px rgba(197, 200, 213, 0.76)', borderRadius: '20px' }}>
                {
                    loading
                        ?
                        <Loading />
                        :
                        <div>
                            <form onSubmit={submitHandler}>
                                <div className='d-flex justify-content-between'>
                                    <div>
                                        <h1 className='text-center'>Upload Blog</h1>
                                    </div>
                                    <div>
                                        <Link to='/admin/blogs' type="button" className="btn-close" aria-label="Close"></Link>
                                    </div>
                                </div>
                                <div className="form-group mt-4">
                                    <label>Blog Title:</label> <br />
                                    <input required type="text" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id='title' name='title' placeholder="Enter Blog Title" onChange={handleCarChange} />
                                </div>
                                <div className="form-group mt-4">
                                    <label>Blog Sub Title:</label> <br />
                                    <input required type="text" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id='title' name='subTitle' placeholder="Enter Blog Sub Title" onChange={handleCarChange} />
                                </div>
                                <div className='form-group'>
                                    <label>Blog Image:</label> <br />
                                    <input required type="file" name='file' onChange={handleImageChange} />
                                </div>
                                <div className='mt-5 mb-5'>
                                    {
                                        file && file?.type.slice(0, 5) === "video" ?
                                            <video controls src={file !== '' ? URL.createObjectURL(file) : ''} className='w-100 h-100' />
                                            :
                                            <img src={file !== '' ? URL.createObjectURL(file) : ''} alt={title} className='w-100 h-100' />
                                    }
                                </div>
                                <div className='form-group'>
                                    <label>Blog Description:</label> <br />
                                    <EditorToolbar />
                                    <ReactQuill formats={formats} modules={modules} theme="snow" placeholder="Blog Description" value={description} onChange={(data) => setDescription(data)} />
                                </div>
                                <button type="submit" size='large' className="btn w-100 mt-4">Submit</button>
                            </form>
                        </div>
                }
            </div>
        </Layout>
    )
}
