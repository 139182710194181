import { Button, Drawer, Dropdown } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import StocksTicker from '../home/StocksTicker'
import { ErrorMessage } from '../Messages/messages';
import "./Navbar.css"

const Navbar = () => {
    const [data, setData] = useState([]);
    const [blogs, setBlogs] = useState([]);
    const [open, setOpen] = useState(false);
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };

    const getData = async () => {
        await axios.get(`https://bazrbuzz.herokuapp.com/api/csv/`).then(res => {
            if (res.statusText === "OK") {
                setData(res.data);
            } else {
                ErrorMessage(res.data.errorMessage);
            }
        })
    }

    console.log(data)

    const getBlogs = async () => {
        await axios.get(`https://bazrbuzz.herokuapp.com/api/blogs`).then(res => {
            if (res.statusText === "OK") {
                setBlogs(res.data);
            } else {
                ErrorMessage(res.data.errorMessage);
            }
        })
    }

    useEffect(() => {
        return () => {
        }
    }, []);

    useEffect(() => {
        getData();
        getBlogs();
        return () => {
        }
    }, []);

    const items = (
        <div className="navMenu smallMenu bg-dark-800 lg:justify-end gap-auto lg:gap-0 m-auto overflow-x-auto space-x-0 lg:space-x-0">
            <NavLink className={({ isActive }) => isActive ? 'activeBtn block p-1 relative text-green-800' : 'p-1 text-white block'} to="/">
                Home
            </NavLink>
            <br />
            <NavLink to={"/blog/" + blogs[0]?._id} className={({ isActive }) => isActive ? 'activeBtn p-1 relative block text-green-800' : 'p-1 text-white block'}>
                Education
            </NavLink>
            <br />
            <NavLink to="/dashboard" className={({ isActive }) => isActive ? 'activeBtn p-1 relative text-green-800 block' : 'p-1 text-white block'}>
                Dashboard
            </NavLink>
            <br />
            <NavLink to="/contact" className={({ isActive }) => isActive ? 'activeBtn p-1 relative text-green-800 block' : 'p-1 text-white block'}>
                Contact
            </NavLink>
        </div>
    );

    return (
        <div style={{ backgroundColor: "rgb(30 34 45 / 1)" }} className='dark:bg-black-500'>
            <StocksTicker data={data.slice(0, 66)} />
            <div className="grid grid-cols-1 lg:grid-cols-2  lg:p-7  p-2 pt-2 overflow-hidden">
                <div className="flex justify-between items-center lg:hidden mb-2 lg:mb-0">
                    <Link to="/">
                        <h2 className="text-lg font-semibold text-gray-800 text-left ml-2 dark:text-white mb-0">
                            <span className="dark:text-white" style={{ color: "white" }}>BAZR</span>
                            <span className="text-green-800">BUZZ</span>
                        </h2>
                    </Link>
                    <div className='dropdownMenu'>
                        {/* <Dropdown
                            overlay={items}
                            placement="bottomLeft"
                        > */}
                        <button className="block lg:hidden" onClick={showDrawer}>
                            <svg className="ml-1 " width="23" height="14" viewBox="0 0 32 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="32" height="3" rx="1.5" fill="#01E4A1" />
                                <rect y="8" width="32" height="3" rx="1.5" fill="#01E4A1" />
                                <rect y="16" width="32" height="3" rx="1.5" fill="#01E4A1" />
                            </svg>
                        </button>
                        <Drawer className='menuDrawer' placement="right" onClose={onClose} open={open}>
                            <NavLink className={({ isActive }) => isActive ? 'activeBtn block p-1 relative text-green-800' : 'p-1 text-white block'} to="/" onClick={onClose}>
                                Home
                            </NavLink>
                            {/* <br /> */}
                            <NavLink to={"/blog/" + blogs[0]?._id} className={({ isActive }) => isActive ? 'activeBtn p-1 relative block text-green-800' : 'p-1 text-white block'} onClick={onClose}>
                                Education
                            </NavLink>
                            {/* <br /> */}
                            <NavLink to="/dashboard" className={({ isActive }) => isActive ? 'activeBtn p-1 relative text-green-800 block' : 'p-1 text-white block'} onClick={onClose}>
                                Dashboard (BETA)
                            </NavLink>
                            {/* <br /> */}
                            <NavLink to="/contact" className={({ isActive }) => isActive ? 'activeBtn p-1 relative text-green-800 block' : 'p-1 text-white block'} onClick={onClose}>
                                Contact
                            </NavLink>
                        </Drawer>
                        {/* </Dropdown> */}
                    </div>
                </div>
                <div className=" text-left lg:flex lg:items-center hidden lg:block">
                    <Link to="/">
                        <h2 className="text-lg font-semibold text-gray-800 text-left dark:text-white ">
                            <span className="dark:text-white " style={{ color: "white" }}>BAZR</span>
                            <span className="text-green-800">BUZZ</span>
                        </h2>
                    </Link>
                </div>
                <div className="">
                    <div className='horizentalMenu'>
                        <div className="lg:text-right flex justify-between navMenu lg:justify-end gap-auto lg:gap-0 m-auto overflow-x-auto space-x-0 lg:space-x-0">
                            <NavLink className={({ isActive }) => isActive ? 'activeBtn sm:p-6 p-1 relative text-green-800' : 'sm:p-6 p-1 text-white'} to="/">
                                Home
                            </NavLink>
                            <NavLink to={"/blog/" + blogs[0]?._id} className={({ isActive }) => isActive ? 'activeBtn sm:p-6 p-1 relative text-green-800' : 'sm:p-6 p-1 text-white'}>
                                Education
                            </NavLink>
                            <NavLink to="/dashboard" className={({ isActive }) => isActive ? 'activeBtn sm:p-6 p-1 relative text-green-800' : 'sm:p-6 p-1 text-white'}>
                                Dashboard (BETA)
                            </NavLink>
                            <NavLink to="/contact" className={({ isActive }) => isActive ? 'activeBtn sm:p-6 p-1 relative text-green-800' : 'sm:p-6 p-1 text-white'}>
                                Contact
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Navbar
