/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/style-prop-object */
import React from 'react';
import ReactECharts from 'echarts-for-react';

function BalanceSheet({ selectedCSV2Stock }) {
  return (
    <div className="overflow-hidden ">
      {/* <div className="text-grey-20  p-4  bg-grey-10">
        <h4 className="text-sm mb-3 text-white font-bold">The current financial position of Apple Inc</h4>
        <p className="text-sm">Total assets of AAPL for Q1 22 is 381.19B USD, 8.60% more than the previous Q4 21. And total liabilities increased by 7.41% in Q1 22 to 309.26B USD.
        </p>
      </div> */}
      <div className="grid md:grid-cols-1 ">
        <div className="w-full h-auto p-1 ">
          <ReactECharts
            option={{
              height: '180px',

              title: {
                text: 'Balance Sheet',
                textStyle: {
                  color: '#d1d4dc',
                  fontSize: '16px',
                }
              },
              tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'cross',
                  crossStyle: {
                    color: '#999'
                  }
                }
              },
              toolbox: {
                feature: {
                  dataView: { show: false, readOnly: false },
                  magicType: { show: false, type: ['line', 'bar'] },
                  restore: { show: false },
                  saveAsImage: { show: false }
                }
              },
              legend: {
                data: ['Total Assets', 'Total Liabilities', 'Profit Margin YoY'],
                top: '88%',
                textStyle: {
                  color: '#fff',
                  fontSize: '12px',
                }
              },
              xAxis: [
                {
                  type: 'category',
                  data: [
                    selectedCSV2Stock?.balance_sheet_3_fiscal_date_annual.slice(2, 4),
                    selectedCSV2Stock?.balance_sheet_2_fiscal_date_annual.slice(2, 4),
                    selectedCSV2Stock?.balance_sheet_1_fiscal_date_annual.slice(2, 4),
                    selectedCSV2Stock?.balance_sheet_0_fiscal_date_annual.slice(2, 4)
                  ],
                  axisPointer: {
                    type: 'shadow'
                  }
                }
              ],
              yAxis: [
                {
                  type: 'value',
                  position: 'right',
                  nameGap: 2,
                  min: 0,
                  max: (selectedCSV2Stock?.balance_sheet_0_assets_total_assets_annual / 1000000000).toFixed(0),
                  interval: selectedCSV2Stock.balance_sheet_0_assets_total_assets_annual / 1000000000 > 500 ? 250 : 50,
                  axisLabel: {
                    formatter: '{value} B'
                  }
                }
              ],
              series: [
                {
                  name: 'Total Assets',
                  type: 'bar',
                  tooltip: {
                    valueFormatter: function (value) {
                      return value + ' B';
                    }
                  },
                  data: [
                    (selectedCSV2Stock?.balance_sheet_3_assets_total_assets_annual / 1000000000).toFixed(2),
                    (selectedCSV2Stock?.balance_sheet_2_assets_total_assets_annual / 1000000000).toFixed(2),
                    (selectedCSV2Stock?.balance_sheet_1_assets_total_assets_annual / 1000000000).toFixed(2),
                    (selectedCSV2Stock?.balance_sheet_0_assets_total_assets_annual / 1000000000).toFixed(2)
                  ]
                },
                {
                  name: 'Total Liabilities',
                  type: 'bar',
                  tooltip: {
                    valueFormatter: function (value) {
                      return value + ' B';
                    }
                  },
                  data: [
                    (selectedCSV2Stock?.balance_sheet_3_liabilities_total_liabilities_annual / 1000000000).toFixed(2),
                    (selectedCSV2Stock?.balance_sheet_2_liabilities_total_liabilities_annual / 1000000000).toFixed(2),
                    (selectedCSV2Stock?.balance_sheet_1_liabilities_total_liabilities_annual / 1000000000).toFixed(2),
                    (selectedCSV2Stock?.balance_sheet_0_liabilities_total_liabilities_annual / 1000000000).toFixed(2)
                  ]
                },
                {
                  name: 'Profit Margin YoY',
                  type: 'line',
                  tooltip: {
                    valueFormatter: function (value) {
                      return value + ' B';
                    }
                  },
                  data: [
                    selectedCSV2Stock?.balance_sheet_3_liabilities_total_liabilities_annual / selectedCSV2Stock?.balance_sheet_3_assets_total_assets_annual,
                    selectedCSV2Stock?.balance_sheet_2_liabilities_total_liabilities_annual / selectedCSV2Stock?.balance_sheet_2_assets_total_assets_annual,
                    selectedCSV2Stock?.balance_sheet_1_liabilities_total_liabilities_annual / selectedCSV2Stock?.balance_sheet_1_assets_total_assets_annual,
                    selectedCSV2Stock?.balance_sheet_0_liabilities_total_liabilities_annual / selectedCSV2Stock?.balance_sheet_0_assets_total_assets_annual,
                  ]
                }
              ]
            }} />
        </div>

      </div>
      {/* <div className="overflow-x-auto max-w-sm m-auto	 md:max-w-full  text-sm text-grey-120  whitespace-nowrap	">
        <div className="">
          <table className=" w-full max-w-sm m-auto	 md:max-w-full  whitespace-nowrap overflow-auto " >
            <thead className=" whitespace-nowrap">
              <tr className="whitespace-nowrap relative z-0 ">
                <th className="text-grey-110 font-normal border-t  border-t-grey-15 w-16   bg-grey-700 px-2 left-0 z-40  ">Currency: USD</th>
                <th className="border-t border-t-grey-15 p-2  text-grey-110  font-normal 	   -z-0">2015</th>
                <th className="border-t border-t-grey-15 p-2  text-grey-110  font-normal   	 -z-0">2016</th>
                <th className="border-t border-t-grey-15 p-2  text-grey-110  font-normal 	 -z-0">2017</th>
                <th className="border-t border-t-grey-15 p-2  text-grey-110 font-normal   	-z-0">2018</th>
                <th className="border-t border-t-grey-15 p-2  text-grey-110 font-normal   	 -z-0">2019</th>
                <th className="border-t border-t-grey-15 p-2  text-grey-110 font-normal  	 -z-0">2020</th>
                <th className="border-t border-t-grey-15 p-2 text-grey-110 font-normal   	 -z-0">2021</th>
              </tr>


            </thead>
            <tbody className=" whitespace-nowrap">
              <tr className="whitespace-nowrap ">

                <th className="border-t border-t-grey-15  px-2 px-4  w-16 left-0 z-9999 whitespace-nowrap">Total liabilities
                </th>
                <th className="border-t border-t-grey-15 p-2 z-0  whitespace-nowrap  	 ">‪290.48B‬<br></br><span className="text-blue-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0 whitespace-nowrap 	">321.69B‬<br></br><span className="text-blue-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0  whitespace-nowrap 	">375.32B‬<br></br><span className="text-red-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0 whitespace-nowrap 	">365.73B‬<br></br><span className="text-blue-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0 whitespace-nowrap 	">‪338.52B<br></br><span className="text-blue-500">+25.29%</span>‬</th>
                <th className="border-t border-t-grey-15 p-2 z-0  whitespace-nowrap 	">323.89B‬<br></br><span className="text-red-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0  whitespace-nowrap 	">351.00B‬<br></br><span className="text-blue-500">+25.29%</span></th>

              </tr>
              <tr >

                <th className="border-t border-t-grey-15  w-16 px-2  bg-grey-700  left-0 z-40 overflow-hidden text-ellipsis">Total equity
                </th>
                <th className="border-t border-t-grey-15 p-2 z-0 ">‪290.48B‬<br></br><span className="text-blue-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0">321.69B‬<br></br><span className="text-blue-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0">375.32B‬<br></br><span className="text-red-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0">365.73B‬<br></br><span className="text-blue-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0">‪338.52B<br></br><span className="text-blue-500">+25.29%</span>‬</th>
                <th className="border-t border-t-grey-15 p-2 z-0 ">323.89B‬<br></br><span className="text-red-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0 ">351.00B‬<br></br><span className="text-blue-500">+25.29%</span></th>

              </tr>
              <tr >

                <th className="border-t border-t-grey-15  w-16 px-2  bg-grey-700  left-0 z-40  	overflow-hidden text-ellipsis" alt="Total liabilities & shareholders' equities"><p className="w-48 overflow-hidden text-ellipsis">Total liabilities & shareholders' equities</p>
                </th>
                <th className="border-t border-t-grey-15 p-2 -z-0 ">‪290.48B‬<br></br><span className="text-blue-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0">321.69B‬<br></br><span className="text-blue-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0">375.32B‬<br></br><span className="text-red-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0">365.73B‬<br></br><span className="text-blue-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0">‪338.52B<br></br><span className="text-blue-500">+25.29%</span>‬</th>
                <th className="border-t border-t-grey-15 p-2 z-0 ">323.89B‬<br></br><span className="text-red-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0 ">351.00B‬<br></br><span className="text-blue-500">+25.29%</span></th>

              </tr>
              <tr >

                <th className="border-t border-t-grey-15  w-16 px-2  bg-grey-700  left-0 z-40 ">Total debt
                </th>
                <th className="border-t border-t-grey-15 p-2 z-0 ">‪290.48B‬<br></br><span className="text-blue-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0">321.69B‬<br></br><span className="text-blue-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0">375.32B‬<br></br><span className="text-red-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0">365.73B‬<br></br><span className="text-blue-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0">‪338.52B<br></br><span className="text-blue-500">+25.29%</span>‬</th>
                <th className="border-t border-t-grey-15 p-2 z-0 ">323.89B‬<br></br><span className="text-red-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0 ">351.00B‬<br></br><span className="text-blue-500">+25.29%</span></th>

              </tr>
              <tr >

                <th className="border-t border-t-grey-15  w-16 px-2  bg-grey-700  left-0 z-40">Net debt
                </th>
                <th className="border-t border-t-grey-15 p-2 z-0 ">‪290.48B‬<br></br><span className="text-blue-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0">321.69B‬<br></br><span className="text-blue-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0">375.32B‬<br></br><span className="text-red-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0">365.73B‬<br></br><span className="text-blue-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0">‪338.52B<br></br><span className="text-blue-500">+25.29%</span>‬</th>
                <th className="border-t border-t-grey-15 p-2 z-0 ">323.89B‬<br></br><span className="text-red-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0 ">351.00B‬<br></br><span className="text-blue-500">+25.29%</span></th>

              </tr>
              <tr >

                <th className="border-t border-t-grey-15  w-16 px-2  bg-grey-700  left-0 z-40">Total assets</th>
                <th className="border-t border-t-grey-15 p-2 z-0 ">‪290.48B‬<br></br><span className="text-blue-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0">321.69B‬<br></br><span className="text-blue-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0">375.32B‬<br></br><span className="text-red-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0">365.73B‬<br></br><span className="text-blue-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0">‪338.52B<br></br><span className="text-blue-500">+25.29%</span>‬</th>
                <th className="border-t border-t-grey-15 p-2 z-0 ">323.89B‬<br></br><span className="text-red-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 z-0 ">351.00B‬<br></br><span className="text-blue-500">+25.29%</span></th>

              </tr>
              <tr >

                <th className="border-t border-t-grey-15  w-16 px-2  bg-grey-700  left-0 z-40"><span className="relative">Total assets</span></th>
                <th className="border-t border-t-grey-15 p-2 -z-0  ">‪290.48B‬<br></br><span className="text-blue-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 -z-0">321.69B‬<br></br><span className="text-blue-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 -z-0">375.32B‬<br></br><span className="text-red-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 -z-0">365.73B‬<br></br><span className="text-blue-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 -z-0">‪338.52B<br></br><span className="text-blue-500">+25.29%</span>‬</th>
                <th className="border-t border-t-grey-15 p-2 -z-0 ">323.89B‬<br></br><span className="text-red-500">+25.29%</span></th>
                <th className="border-t border-t-grey-15 p-2 -z-0 ">351.00B‬<br></br><span className="text-blue-500">+25.29%</span></th>

              </tr>

            </tbody>

          </table>

        </div>

      </div> */}








    </div>




  );
}

export default BalanceSheet;
